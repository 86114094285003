/*
 * PALETTE COLORS FOR NEW DESIGN
 * $<theme>-colors-<color_name>
 */
/* CUSTOM COLORS FOR THEME */
/* Light theme shadows */
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
.text_left {
  text-align: left;
}

/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*
 * @param $theme - map with abstract colors
 * @param $custom - map with custom colors for components
 */
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
.text_left {
  text-align: left;
}

/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
.popup-choose-document__autocomplete {
  margin: 2rem 5% 4rem 5%;
  width: 90%;
}
.popup-choose-document__btn-container {
  margin-top: 2rem;
  width: 100%;
  text-align: right;
}
.popup-choose-document__btn-ok {
  margin-right: 1rem;
  padding-right: 3rem;
  padding-left: 3rem;
}

.start-learning-popup {
  width: 100%;
  text-align: right;
  margin-top: 1rem;
}

.status-box {
  align-items: center;
  display: flex;
  justify-content: center;
}
.status-box__icon {
  border-radius: 100%;
  height: 0.5714285714rem;
  width: 0.5714285714rem;
}
.status-box__text {
  margin-left: 0.5rem;
}

.choose-document {
  display: block;
  position: relative;
}
.choose-document__mobile {
  display: none;
}
.choose-document__item-subtitle {
  display: inline-block;
  margin: 0.6rem 0.2rem 0 0;
}
.choose-document__toggle {
  display: flex;
  justify-content: flex-end;
}
.choose-document__toggle-btn {
  width: 17rem;
  padding: 0.5rem 1.71428571rem;
}
.choose-document__toggle-btn_disabled {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.7rem 1.71428571rem;
}
.choose-document__no-documents {
  width: 20rem;
}
.choose-document__btn-container {
  margin-top: -3rem;
}
.choose-document__status-box {
  margin-bottom: 1rem;
}
.choose-document__toggle-mobile {
  display: none;
}

@media (max-width: 767px) {
  .choose-document__desktop {
    display: none;
  }
  .choose-document__mobile {
    display: block;
  }
  .choose-document__item-subtitle-value {
    display: block;
  }
  .choose-document__item-subtitle {
    margin-bottom: 0.2rem;
  }
  .choose-document__toggle-btn {
    padding: 0.85714286rem 0.5rem;
    width: 100%;
  }
  .choose-document__status-box {
    justify-content: flex-start;
  }
  .choose-document__toggle-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 1.6428571429rem;
    margin-top: 1.5rem;
  }
  .choose-document__no-documents {
    width: 100%;
    margin-bottom: 2rem;
  }
  .choose-document__toggle-mobile-text {
    margin-right: auto;
  }
  .choose-document__documents-autocomplete-mobile {
    display: block;
  }
  .cycle-info {
    align-items: stretch;
    flex-direction: column;
  }
  .cycle-info__item {
    margin-bottom: 0.6rem;
  }
  .cycle-info__priority-organization {
    width: 100%;
  }
  .cycle-info__box {
    order: 2;
  }
}
.mobile-version .choose-document__desktop {
  display: none;
}
.mobile-version .choose-document__mobile {
  display: block;
}
.mobile-version .choose-document__item-subtitle-value {
  display: block;
}
.mobile-version .choose-document__item-subtitle {
  margin-bottom: 0.2rem;
}
.mobile-version .choose-document__toggle-btn {
  padding: 0.85714286rem 0.5rem;
  width: 100%;
}
.mobile-version .choose-document__status-box {
  justify-content: flex-start;
}
.mobile-version .choose-document__toggle-mobile {
  display: flex;
  align-items: center;
  margin-bottom: 1.6428571429rem;
  margin-top: 1.5rem;
}
.mobile-version .choose-document__no-documents {
  width: 100%;
  margin-bottom: 2rem;
}
.mobile-version .choose-document__toggle-mobile-text {
  margin-right: auto;
}
.mobile-version .choose-document__documents-autocomplete-mobile {
  display: block;
}
.mobile-version .cycle-info {
  align-items: stretch;
  flex-direction: column;
}
.mobile-version .cycle-info__item {
  margin-bottom: 0.6rem;
}
.mobile-version .cycle-info__priority-organization {
  width: 100%;
}
.mobile-version .cycle-info__box {
  order: 2;
}

::ng-deep .mobile-version .choose-document__desktop {
  display: none;
}
::ng-deep .mobile-version .choose-document__mobile {
  display: block;
}
::ng-deep .mobile-version .choose-document__item-subtitle-value {
  display: block;
}
::ng-deep .mobile-version .choose-document__item-subtitle {
  margin-bottom: 0.2rem;
}
::ng-deep .mobile-version .choose-document__toggle-btn {
  padding: 0.85714286rem 0.5rem;
  width: 100%;
}
::ng-deep .mobile-version .choose-document__status-box {
  justify-content: flex-start;
}
::ng-deep .mobile-version .choose-document__toggle-mobile {
  display: flex;
  align-items: center;
  margin-bottom: 1.6428571429rem;
  margin-top: 1.5rem;
}
::ng-deep .mobile-version .choose-document__no-documents {
  width: 100%;
  margin-bottom: 2rem;
}
::ng-deep .mobile-version .choose-document__toggle-mobile-text {
  margin-right: auto;
}
::ng-deep .mobile-version .choose-document__documents-autocomplete-mobile {
  display: block;
}
::ng-deep .mobile-version .cycle-info {
  align-items: stretch;
  flex-direction: column;
}
::ng-deep .mobile-version .cycle-info__item {
  margin-bottom: 0.6rem;
}
::ng-deep .mobile-version .cycle-info__priority-organization {
  width: 100%;
}
::ng-deep .mobile-version .cycle-info__box {
  order: 2;
}

/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/
/* --- BASE ELEMENTS --- */
html {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
@media (min-width: 1820px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 1820px) {
  html {
    font-size: 18px;
  }
}
@media (min-width: 2500px) and (min-height: 1400px) {
  html {
    font-size: 26px;
  }
}
@media (min-width: 768px) and (max-width: 1333px) {
  html {
    font-size: calc((100vw - 960px) / 186 + 11px);
  }
}
@media (min-width: 768px) and (max-width: 960px) {
  html {
    font-size: calc((100vw - 768px) / 96 + 8px);
  }
}

div.hopscotch-bubble {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1428571429rem;
}
div.hopscotch-bubble .hopscotch-bubble-number {
  font-size: 1.1428571429rem;
}
div.hopscotch-bubble .hopscotch-content {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1428571429rem;
}
div.hopscotch-bubble .hopscotch-nav-button {
  font-size: 0.85714286rem;
}

.button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  /*@include tablet {
    &_base-tb {
      font-size: map-get($font-size, base);
    }

    &_small-tb {
      font-size: map-get($font-size, small);
    }
  }*/
}
.button_size-sm {
  font-size: 0.85714286rem;
}
.button_base-small {
  font-size: 0.9286rem;
}
@media (max-width: 767px) {
  .button_small-sm {
    font-size: 0.85714286rem;
  }
  .button_bold-sm {
    font-weight: 600;
  }
}
.button .mobile-version_small-sm {
  font-size: 0.85714286rem;
}
.button .mobile-version_bold-sm {
  font-weight: 600;
}
.button ::ng-deep .mobile-version_small-sm {
  font-size: 0.85714286rem;
}
.button ::ng-deep .mobile-version_bold-sm {
  font-weight: 600;
}

.form-control {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}

.range-slider__value {
  font-weight: 600;
}

.text-field {
  font-family: "Open Sans", sans-serif;
  font-size: 1.1428571429rem;
}
.text-field__big-text {
  font-size: 1.9rem;
}

.autocomplete_theme_cycles .text-field {
  font-size: 1rem;
}
.autocomplete_theme_statistics .text-field {
  font-size: 1.42857rem;
}
.autocomplete_theme_statistics .form-control {
  font-size: 0.85714286rem;
}
.autocomplete_theme_edu-program-info .text-field {
  font-size: 0.85714286rem;
}
@media (max-width: 767px) {
  .autocomplete_theme_text-sm-mobile .form-control__label {
    font-size: 0.85714286rem;
  }
}
.autocomplete_theme_text-sm-mobile .mobile-version .form-control__label {
  font-size: 0.85714286rem;
}
.autocomplete_theme_text-sm-mobile ::ng-deep .mobile-version .form-control__label {
  font-size: 0.85714286rem;
}

.header__link, .submenu__toggle {
  font-weight: 500;
}

/*@include tablet {

  .header__nav_drop {

    .header__link, .submenu__toggle {
      font-weight: map-get($font-weight, bold);
      text-transform: uppercase;
      letter-spacing: 0.05rem;

      &_small-tablet {
        text-transform: none;
        font-size: map-get($font-size, small);
        letter-spacing: 0;
      }
    }
  }
}*/
@media (max-width: 767px) {
  .header__link, .submenu__toggle {
    font-size: 0.85714286rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
  .header__mobile-submenu-back {
    font-size: 0.85714286rem;
  }
}
.mobile-version .header__link, .mobile-version .submenu__toggle {
  font-size: 0.85714286rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
.mobile-version .header__mobile-submenu-back {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .header__link, ::ng-deep .mobile-version .submenu__toggle {
  font-size: 0.85714286rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}
::ng-deep .mobile-version .header__mobile-submenu-back {
  font-size: 0.85714286rem;
}

.heading {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.heading_1 {
  font-size: 2.28571429rem;
}
.heading_1_mobile {
  font-size: 2.28571429rem;
}
.heading_2 {
  font-size: 1.71428571rem;
}
.heading_3 {
  font-size: 1.2857142857rem;
}
.heading_4 {
  font-size: 1.1428571429rem;
}
.heading_light {
  font-weight: 400;
}
.heading_medium {
  font-weight: 500;
}
.heading__secondary {
  font-weight: 400;
}

/* @include tablet {
   .heading {
     &_1 {
       font-size: map-get($font-size, h1-tablet);
     }
   }
 }*/
@media (max-width: 767px) {
  .heading_1 {
    font-size: 1.42857rem;
  }
  .heading_1-smaller-sm {
    font-size: 1.2857142857rem;
  }
  .heading_1_mobile {
    font-size: 1.42857rem;
  }
  .heading_2 {
    font-size: 1.2857142857rem;
  }
  .heading_2-smaller-sm {
    font-size: 1.1428571429rem;
  }
  .heading_bold-sm {
    font-weight: 600;
  }
}
.mobile-version .heading_1 {
  font-size: 1.42857rem;
}
.mobile-version .heading_1-smaller-sm {
  font-size: 1.2857142857rem;
}
.mobile-version .heading_1_mobile {
  font-size: 1.42857rem;
}
.mobile-version .heading_2 {
  font-size: 1.2857142857rem;
}
.mobile-version .heading_2-smaller-sm {
  font-size: 1.1428571429rem;
}
.mobile-version .heading_bold-sm {
  font-weight: 600;
}

::ng-deep .mobile-version .heading_1 {
  font-size: 1.42857rem;
}
::ng-deep .mobile-version .heading_1-smaller-sm {
  font-size: 1.2857142857rem;
}
::ng-deep .mobile-version .heading_1_mobile {
  font-size: 1.42857rem;
}
::ng-deep .mobile-version .heading_2 {
  font-size: 1.2857142857rem;
}
::ng-deep .mobile-version .heading_2-smaller-sm {
  font-size: 1.1428571429rem;
}
::ng-deep .mobile-version .heading_bold-sm {
  font-weight: 600;
}

.text {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
.text_h0 {
  font-size: 3.5rem;
}
.text_h1 {
  font-size: 2.28571429rem;
}
.text_h1-mobile {
  font-size: 1.42857rem;
}
.text_h2 {
  font-size: 1.71428571rem;
}
.text_h2-mobile {
  font-size: 1.2857142857rem;
}
.text_h3 {
  font-size: 1.2857142857rem;
}
.text_bigger {
  font-size: 1.42857rem;
}
.text_big {
  font-size: 1.1428571429rem;
}
.text_base {
  font-size: 1rem;
}
.text_base-small {
  font-size: 0.9286rem;
}
.text_small {
  font-size: 0.85714286rem;
}
.text_smaller {
  font-size: 0.785714rem;
}
.text_tiny {
  font-size: 0.71428571rem;
}
.text_light {
  font-weight: 300;
}
.text_regular {
  font-weight: 400;
}
.text_medium {
  font-weight: 500;
}
.text_bold {
  font-weight: 600;
}
.text_bolder {
  font-weight: 700;
}
.text_italic {
  font-style: italic;
}

/*@include tablet {
  .text {

    &_base-tb {
      font-size: map-get($font-size, base);
    }

    &_base-small-tb {
      font-size: map-get($font-size, base-small);
    }

    &_small-tb {
      font-size: map-get($font-size, small);
    }

    &_tiny-tb {
      font-size: map-get($font-size, tiny);
    }

    &_regular-tb {
      font-weight: map-get($font-weight, regular);
    }

  }
}*/
@media (max-width: 767px) {
  .text_base-sm {
    font-size: 1rem;
  }
  .text_small-sm {
    font-size: 0.85714286rem;
  }
  .text_tiny-sm {
    font-size: 0.71428571rem;
  }
  .text_regular-sm {
    font-weight: 400;
  }
  .text_bold-sm {
    font-weight: 600;
  }
}
.mobile-version .text_base-sm {
  font-size: 1rem;
}
.mobile-version .text_small-sm {
  font-size: 0.85714286rem;
}
.mobile-version .text_tiny-sm {
  font-size: 0.71428571rem;
}
.mobile-version .text_regular-sm {
  font-weight: 400;
}
.mobile-version .text_bold-sm {
  font-weight: 600;
}

::ng-deep .mobile-version .text_base-sm {
  font-size: 1rem;
}
::ng-deep .mobile-version .text_small-sm {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .text_tiny-sm {
  font-size: 0.71428571rem;
}
::ng-deep .mobile-version .text_regular-sm {
  font-weight: 400;
}
::ng-deep .mobile-version .text_bold-sm {
  font-weight: 600;
}

.loader__text {
  font-size: 1.71428571rem;
}

.tag {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85714286rem;
  font-weight: 300;
}

.tab-group {
  font-family: "Open Sans", sans-serif;
}
.tab-group_theme_search-tags .tab-group__tab {
  font-family: "Open Sans", sans-serif;
  font-size: 0.85714286rem;
}
.tab-group_theme_search .tab-group__header {
  font-weight: 500;
}
.multi-zet__text-multiplier {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .multi-zet_theme_search-mobile .multi-zet__text, .multi-zet_theme_search-mobile .multi-zet__label {
    font-weight: 400;
  }
}
.mobile-version .multi-zet_theme_search-mobile .multi-zet__text, .mobile-version .multi-zet_theme_search-mobile .multi-zet__label {
  font-weight: 400;
}

::ng-deep .mobile-version .multi-zet_theme_search-mobile .multi-zet__text, ::ng-deep .mobile-version .multi-zet_theme_search-mobile .multi-zet__label {
  font-weight: 400;
}

.included-topic__grid-column {
  font-size: 0.9286rem;
}
.included-topic__grid-column_edu-dates {
  font-size: 0.85714286rem;
}
.included-topic__grid-column_title {
  font-size: 1rem;
}
.included-topic__icon svg {
  width: 1.3rem;
  height: 1.3rem;
}

@media (max-width: 767px) {
  .included-topic__icon svg {
    width: 1rem;
    height: 1rem;
  }
}
.mobile-version .included-topic__icon svg {
  width: 1rem;
  height: 1rem;
}

::ng-deep .mobile-version .included-topic__icon svg {
  width: 1rem;
  height: 1rem;
}

@media (max-width: 767px) {
  .markers-zet_theme_search-mobile .markers-zet__text, .markers-zet_theme_search-mobile .markers-zet__label {
    font-weight: 400;
  }
}
.markers-zet_theme_search-mobile .mobile-version .markers-zet__text, .markers-zet_theme_search-mobile .mobile-version .markers-zet__label {
  font-weight: 400;
}
.markers-zet_theme_search-mobile ::ng-deep .mobile-version .markers-zet__text, .markers-zet_theme_search-mobile ::ng-deep .mobile-version .markers-zet__label {
  font-weight: 400;
}

.like-dislike__text {
  font-size: 0.71428571rem;
}

@media (max-width: 767px) {
  .like-dislike__text_large-tb {
    font-size: 1rem;
  }
}
.mobile-version .like-dislike__text_large-tb {
  font-size: 1rem;
}

::ng-deep .mobile-version .like-dislike__text_large-tb {
  font-size: 1rem;
}

/*@include tablet {
  .like-dislike {

    &__text {

      &_large-tb {
        font-size: map-get($font-size, base);
      }
    }
  }
}*/
.show-more-component-heading__text {
  font-size: 1.1428571429rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  .show-more-component-heading__text {
    font-size: 1rem;
    font-weight: 600;
  }
}
.show-more-component .mobile-version-heading__text {
  font-size: 1rem;
  font-weight: 600;
}
.show-more-component ::ng-deep .mobile-version-heading__text {
  font-size: 1rem;
  font-weight: 600;
}

.rating-container__text {
  font-size: 0.71428571rem;
}
.rating-container__inner-text {
  font-size: 0.42857142rem;
}

@media (max-width: 767px) {
  .rating-container__inner-text {
    font-size: 0.85714286rem;
  }
}
.mobile-version .rating-container__inner-text {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .rating-container__inner-text {
  font-size: 0.85714286rem;
}

.search-wizard__title {
  font-size: 1rem;
  font-weight: 400;
}
@media (max-width: 767px) {
  .search-wizard__title {
    font-size: 0.71428571rem;
  }
}
.search-wizard__title .mobile-version {
  font-size: 0.71428571rem;
}
.search-wizard__title ::ng-deep .mobile-version {
  font-size: 0.71428571rem;
}
.search-wizard__subtitle {
  font-weight: 500;
}
@media (max-width: 767px) {
  .search-wizard__subtitle {
    font-size: 1rem;
  }
}
.search-wizard__subtitle .mobile-version {
  font-size: 1rem;
}
.search-wizard__subtitle ::ng-deep .mobile-version {
  font-size: 1rem;
}
.search-wizard__content-name {
  font-weight: 500;
}

.switch-button__text {
  font-size: 1.1428571429rem;
}

.search__heading {
  font-weight: 400;
}
.search-sidebar__rating-text {
  font-size: 0.71428571rem;
}
.search-item__name {
  font-size: 1.1428571429rem;
  font-weight: 600;
  line-height: 1.4;
}
.search-item__division {
  font-size: 0.71428571rem;
}
.search-item__region {
  font-size: 0.85714286rem;
}
.search-item__type {
  font-size: 0.85714286rem;
}
.search-item__iom-type {
  font-size: 0.71428571rem;
  text-transform: uppercase;
}
.search-item__iom-kind {
  font-size: 0.85714286rem;
}
.search-item__form {
  font-size: 0.85714286rem;
}
.search-item__price {
  font-size: 1.1428571429rem;
  font-weight: 600;
}
.search-item__price span {
  font-weight: 400;
}
.search-item__dates {
  font-size: 1.1428571429rem;
  font-weight: 600;
}
@media (max-width: 767px) {
  .search-item__name {
    font-size: 1rem;
  }
  .search-item__organization {
    font-size: 0.85714286rem;
    line-height: 1.3;
  }
  .search-item__date-range {
    font-size: 1rem;
  }
  .search-item__date-range-title {
    font-weight: 600;
  }
  .search-item__price {
    font-weight: 500;
  }
}
.search-item .mobile-version__name {
  font-size: 1rem;
}
.search-item .mobile-version__organization {
  font-size: 0.85714286rem;
  line-height: 1.3;
}
.search-item .mobile-version__date-range {
  font-size: 1rem;
}
.search-item .mobile-version__date-range-title {
  font-weight: 600;
}
.search-item .mobile-version__price {
  font-weight: 500;
}
.search-item ::ng-deep .mobile-version__name {
  font-size: 1rem;
}
.search-item ::ng-deep .mobile-version__organization {
  font-size: 0.85714286rem;
  line-height: 1.3;
}
.search-item ::ng-deep .mobile-version__date-range {
  font-size: 1rem;
}
.search-item ::ng-deep .mobile-version__date-range-title {
  font-weight: 600;
}
.search-item ::ng-deep .mobile-version__price {
  font-weight: 500;
}

.datepicker__input::placeholder {
  font-size: 0.85714286rem;
}
.datepicker__input:-ms-input-placeholder {
  font-size: 1.1428571429rem;
}
@media (max-width: 767px) {
  .datepicker_theme_text-sm-mobile .form-control__label {
    font-size: 0.85714286rem;
  }
}
.datepicker_theme_text-sm-mobile .mobile-version .form-control__label {
  font-size: 0.85714286rem;
}
.datepicker_theme_text-sm-mobile ::ng-deep .mobile-version .form-control__label {
  font-size: 0.85714286rem;
}

.topics__show-more {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .topics__show-more {
    font-size: 1rem;
  }
}
.mobile-version .topics__show-more {
  font-size: 1rem;
}

::ng-deep .mobile-version .topics__show-more {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .radio__text {
    font-size: 1rem;
  }
}
.mobile-version .radio__text {
  font-size: 1rem;
}

::ng-deep .mobile-version .radio__text {
  font-size: 1rem;
}

.toast-close-button {
  font-size: 1.42857rem;
}

/* --- SCREEN ELEMENTS --- */
.edu-header {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
}
.edu-header__heading {
  font-weight: 400;
}

@media (max-width: 767px) {
  .edu-header__title {
    font-size: 0.85714286rem;
  }
  .edu-header__heading {
    font-size: 1.2857142857rem;
    font-weight: 600;
  }
}
.mobile-version .edu-header__title {
  font-size: 0.85714286rem;
}
.mobile-version .edu-header__heading {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

::ng-deep .mobile-version .edu-header__title {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-header__heading {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

/*
@include tablet {
  .edu-section {
    &__heading {
      font-size: map-get($font-size, h1-mobile);
    }
  }
}
*/
/*@include tablet {
  .edu-header {
    &__heading {
      font-size: map-get($font-size, h3);
    }
  }
}*/
@media (max-width: 767px) {
  .edu-section__heading {
    font-size: 1.1428571429rem;
  }
}
.mobile-version .edu-section__heading {
  font-size: 1.1428571429rem;
}

::ng-deep .mobile-version .edu-section__heading {
  font-size: 1.1428571429rem;
}

.iam-screen__error {
  font-size: 0.85714286rem;
}

/*@include tablet {
  .iam-screen {
    &__error {
      font-size: map-get($font-size, small);
    }
  }
}*/
@media (max-width: 767px) {
  .iam-screen__error {
    font-size: 1rem;
  }
}
.mobile-version .iam-screen__error {
  font-size: 1rem;
}

::ng-deep .mobile-version .iam-screen__error {
  font-size: 1rem;
}

.submenu {
  font-family: "Open Sans", sans-serif;
}
.submenu__notifications-header._flex .text {
  font-weight: 400;
}
.submenu-list__link {
  font-size: 1rem;
  font-weight: 400;
}
.submenu-list__link_notification {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .submenu-list__link {
    font-size: 0.85714286rem;
  }
}
.mobile-version .submenu-list__link {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .submenu-list__link {
  font-size: 0.85714286rem;
}

.login__esia-link {
  font-size: 1.2857142857rem;
}

@media (max-width: 767px) {
  .adl__header-doc-number {
    font-size: 1.1428571429rem;
  }
  .adl-info__label {
    font-size: 0.85714286rem;
  }
  .adl-info__label b, .adl-info__label strong {
    font-weight: 600;
  }
  .adl-info__value {
    font-size: 0.85714286rem;
  }
  .adl-info__autocomplete {
    margin: 0.5rem 0 0;
  }
}
.mobile-version .adl__header-doc-number {
  font-size: 1.1428571429rem;
}
.mobile-version .adl-info__label {
  font-size: 0.85714286rem;
}
.mobile-version .adl-info__label b, .mobile-version .adl-info__label strong {
  font-weight: 600;
}
.mobile-version .adl-info__value {
  font-size: 0.85714286rem;
}
.mobile-version .adl-info__autocomplete {
  margin: 0.5rem 0 0;
}

::ng-deep .mobile-version .adl__header-doc-number {
  font-size: 1.1428571429rem;
}
::ng-deep .mobile-version .adl-info__label {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .adl-info__label b, ::ng-deep .mobile-version .adl-info__label strong {
  font-weight: 600;
}
::ng-deep .mobile-version .adl-info__value {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .adl-info__autocomplete {
  margin: 0.5rem 0 0;
}

.add-doc__heading {
  font-weight: 500;
}
@media (max-width: 767px) {
  .add-doc__heading {
    font-weight: 600;
    line-height: 1.2;
  }
  .add-doc__add-edu-org-cont {
    font-size: 0.85714286rem;
  }
}
.add-doc .mobile-version__heading {
  font-weight: 600;
  line-height: 1.2;
}
.add-doc .mobile-version__add-edu-org-cont {
  font-size: 0.85714286rem;
}
.add-doc ::ng-deep .mobile-version__heading {
  font-weight: 600;
  line-height: 1.2;
}
.add-doc ::ng-deep .mobile-version__add-edu-org-cont {
  font-size: 0.85714286rem;
}

.profile-main__overlay {
  font-size: 0.85714286rem;
}
.profile-main__info-field_name {
  font-weight: 600;
  font-size: 1.2857142857rem;
}
.profile-main__navigation-link {
  font-size: 1.1428571429rem;
  font-weight: 400;
}
.profile-main__navigation-link_active {
  font-weight: 500;
}

@media (max-width: 767px) {
  .profile-main__title {
    font-weight: 600;
    font-size: 1.42857rem;
  }
  .profile-main__info-field {
    font-size: 1rem;
  }
  .profile-main__info-field_snils {
    font-size: 0.85714286rem;
  }
  .profile-main__navigation-link_mobile {
    font-weight: 600;
  }
  .profile-main__navigation-link-pic {
    font-weight: 300;
  }
  .profile-main__photo-edit-icon {
    font-size: 1.1428571429rem;
  }
  .profile-main__field-icon svg {
    width: 1.2rem;
    height: 1.2rem;
  }
  .profile-main__field-icon_mail svg {
    width: 1rem;
    height: 1rem;
  }
}
.mobile-version .profile-main__title {
  font-weight: 600;
  font-size: 1.42857rem;
}
.mobile-version .profile-main__info-field {
  font-size: 1rem;
}
.mobile-version .profile-main__info-field_snils {
  font-size: 0.85714286rem;
}
.mobile-version .profile-main__navigation-link_mobile {
  font-weight: 600;
}
.mobile-version .profile-main__navigation-link-pic {
  font-weight: 300;
}
.mobile-version .profile-main__photo-edit-icon {
  font-size: 1.1428571429rem;
}
.mobile-version .profile-main__field-icon svg {
  width: 1.2rem;
  height: 1.2rem;
}
.mobile-version .profile-main__field-icon_mail svg {
  width: 1rem;
  height: 1rem;
}

::ng-deep .mobile-version .profile-main__title {
  font-weight: 600;
  font-size: 1.42857rem;
}
::ng-deep .mobile-version .profile-main__info-field {
  font-size: 1rem;
}
::ng-deep .mobile-version .profile-main__info-field_snils {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .profile-main__navigation-link_mobile {
  font-weight: 600;
}
::ng-deep .mobile-version .profile-main__navigation-link-pic {
  font-weight: 300;
}
::ng-deep .mobile-version .profile-main__photo-edit-icon {
  font-size: 1.1428571429rem;
}
::ng-deep .mobile-version .profile-main__field-icon svg {
  width: 1.2rem;
  height: 1.2rem;
}
::ng-deep .mobile-version .profile-main__field-icon_mail svg {
  width: 1rem;
  height: 1rem;
}

.zet-count__number {
  font-size: 4.571rem;
  font-weight: 300;
}
.zet-count__unit {
  font-size: 1rem;
}

/*@include tablet {
  .zet-count {
    &__number {
      font-size: map-get($custom-size, tablet);
      font-weight: map-get($font-weight, regular);
    }
  }
}*/
@media (max-width: 767px) {
  .zet-count__number {
    font-size: 2.8571428571rem;
  }
}
.mobile-version .zet-count__number {
  font-size: 2.8571428571rem;
}

::ng-deep .mobile-version .zet-count__number {
  font-size: 2.8571428571rem;
}

@media (max-width: 767px) {
  .schedule__title {
    font-weight: 600;
    font-size: 1.42857rem;
  }
  .schedule__post-title {
    font-size: 0.85714286rem;
  }
}
.mobile-version .schedule__title {
  font-weight: 600;
  font-size: 1.42857rem;
}
.mobile-version .schedule__post-title {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .schedule__title {
  font-weight: 600;
  font-size: 1.42857rem;
}
::ng-deep .mobile-version .schedule__post-title {
  font-size: 0.85714286rem;
}

.user-account-profile-document__item-subtitle, .user-account-profile-document__item-subtitle-value {
  font-size: 1.1428571429rem;
}

@media (max-width: 767px) {
  .user-account-profile-document__item-title {
    font-weight: 600;
    font-size: 1.42857rem;
  }
  .user-account-profile-document__item-subtitle, .user-account-profile-document__item-subtitle-value {
    font-size: 1rem;
  }
  .add-new-document__text {
    font-weight: 600;
  }
}
.mobile-version .user-account-profile-document__item-title {
  font-weight: 600;
  font-size: 1.42857rem;
}
.mobile-version .user-account-profile-document__item-subtitle, .mobile-version .user-account-profile-document__item-subtitle-value {
  font-size: 1rem;
}
.mobile-version .add-new-document__text {
  font-weight: 600;
}

::ng-deep .mobile-version .user-account-profile-document__item-title {
  font-weight: 600;
  font-size: 1.42857rem;
}
::ng-deep .mobile-version .user-account-profile-document__item-subtitle, ::ng-deep .mobile-version .user-account-profile-document__item-subtitle-value {
  font-size: 1rem;
}
::ng-deep .mobile-version .add-new-document__text {
  font-weight: 600;
}

.user-account-profile-certificate__value {
  font-size: 1.1428571429rem;
}
.user-account-profile-certificate__subtitle {
  font-size: 1rem;
}
.user-account-profile-certificate__header {
  font-size: 1.1428571429rem;
}
@media (max-width: 767px) {
  .user-account-profile-certificate__value {
    font-weight: 300;
    font-size: 1rem;
  }
  .user-account-profile-certificate__header {
    font-size: 1rem;
  }
}
.user-account-profile-certificate .mobile-version__value {
  font-weight: 300;
  font-size: 1rem;
}
.user-account-profile-certificate .mobile-version__header {
  font-size: 1rem;
}
.user-account-profile-certificate ::ng-deep .mobile-version__value {
  font-weight: 300;
  font-size: 1rem;
}
.user-account-profile-certificate ::ng-deep .mobile-version__header {
  font-size: 1rem;
}

.profile-settings__access-title {
  font-size: 1.1428571429rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .profile-settings__access-control .checkbox__label {
    font-size: 1rem;
  }
  .profile-settings__name {
    font-size: 0.85714286rem;
  }
  .profile-settings__access-control-testing-link {
    font-size: 0.85714286rem;
  }
}
.mobile-version .profile-settings__access-control .checkbox__label {
  font-size: 1rem;
}
.mobile-version .profile-settings__name {
  font-size: 0.85714286rem;
}
.mobile-version .profile-settings__access-control-testing-link {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .profile-settings__access-control .checkbox__label {
  font-size: 1rem;
}
::ng-deep .mobile-version .profile-settings__name {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .profile-settings__access-control-testing-link {
  font-size: 0.85714286rem;
}

.profile-title-item__subtitle {
  font-size: 1rem;
  font-weight: 600;
}
.profile-title-item__position {
  font-weight: 600;
}

@media (max-width: 767px) {
  .profile-title-item__value {
    font-weight: 300;
  }
}
.mobile-version .profile-title-item__value {
  font-weight: 300;
}

::ng-deep .mobile-version .profile-title-item__value {
  font-weight: 300;
}

.edu-element__go-back {
  font-weight: 600;
}

@media (max-width: 767px) {
  .edu-element__go-back {
    font-size: 0.85714286rem;
  }
}
.mobile-version .edu-element__go-back {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .edu-element__go-back {
  font-size: 0.85714286rem;
}

.edu-element-rating__current-estimate {
  font-size: 5rem;
}

/*@include tablet {
  .edu-element-rating {

    &__current-estimate {
      font-size: 4.285714285714286rem;
      font-weight: map-get($font-weight, regular);
    }
  }
}*/
@media (max-width: 767px) {
  .edu-element-rating__current-estimate {
    font-size: 2.8571428571rem;
  }
  .edu-element-rating__max-estimate {
    font-size: 0.71428571rem;
  }
}
.mobile-version .edu-element-rating__current-estimate {
  font-size: 2.8571428571rem;
}
.mobile-version .edu-element-rating__max-estimate {
  font-size: 0.71428571rem;
}

::ng-deep .mobile-version .edu-element-rating__current-estimate {
  font-size: 2.8571428571rem;
}
::ng-deep .mobile-version .edu-element-rating__max-estimate {
  font-size: 0.71428571rem;
}

.edu-element-view-title__sub-title {
  font-weight: 400;
  font-size: 1rem;
}
.edu-element-view-title__notification {
  border-radius: 0.3rem;
  font-size: 1.1428571429rem;
}

@media (max-width: 767px) {
  .edu-element-view-title__sub-title {
    font-size: 0.85714286rem;
  }
  .edu-element-view-title__main-title {
    font-size: 1.2857142857rem;
  }
}
.mobile-version .edu-element-view-title__sub-title {
  font-size: 0.85714286rem;
}
.mobile-version .edu-element-view-title__main-title {
  font-size: 1.2857142857rem;
}

::ng-deep .mobile-version .edu-element-view-title__sub-title {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-element-view-title__main-title {
  font-size: 1.2857142857rem;
}

/*@include tablet {
  .edu-specifics {
    &__section-title {
      font-weight: map-get($font-weight, regular);
    }
  }
}*/
@media (max-width: 767px) {
  .edu-specifics__section-title {
    font-size: 0.85714286rem;
  }
  .edu-specifics__section-subtitle {
    font-size: 0.85714286rem;
  }
  .edu-specifics__description {
    font-size: 0.85714286rem;
  }
}
.mobile-version .edu-specifics__section-title {
  font-size: 0.85714286rem;
}
.mobile-version .edu-specifics__section-subtitle {
  font-size: 0.85714286rem;
}
.mobile-version .edu-specifics__description {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .edu-specifics__section-title {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-specifics__section-subtitle {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-specifics__description {
  font-size: 0.85714286rem;
}

.see-annotation__link {
  font-size: 0.785714rem;
}

/*@include tablet {
  .see-annotation {
    &__link {
      font-size: map-get($font-size, base);
    }
  }
}*/
@media (max-width: 767px) {
  .see-annotation__link {
    font-size: 0.85714286rem;
  }
}
.mobile-version .see-annotation__link {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .see-annotation__link {
  font-size: 0.85714286rem;
}

.portfolio__filters-button, .portfolio__filters-button-mobile, .portfolio__filters-download-button, .portfolio__filters-download-button-mobile, .portfolio__filters-download-button-portfolio {
  font-weight: 600;
}
.portfolio__filters-download-button, .portfolio__filters-button-mobile, .portfolio__filters-download-button-portfolio, .portfolio__filters-download-button-mobile {
  font-size: 0.85714286rem;
}
.portfolio__grid .grid__column {
  font-size: 0.9rem;
}
.portfolio__title {
  font-weight: 400;
}

@media (max-width: 767px) {
  .portfolio__title {
    font-weight: 600;
  }
  .portfolio__filters-button, .portfolio__filters-download-button {
    font-size: 0.85714286rem;
  }
}
.mobile-version .portfolio__title {
  font-weight: 600;
}
.mobile-version .portfolio__filters-button, .mobile-version .portfolio__filters-download-button {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .portfolio__title {
  font-weight: 600;
}
::ng-deep .mobile-version .portfolio__filters-button, ::ng-deep .mobile-version .portfolio__filters-download-button {
  font-size: 0.85714286rem;
}

@media (min-width: 768px) and (max-width: 1333px) {
  .portfolio__date-cell_value {
    font-size: 0.9286rem;
  }
}
/*
@include tablet {
  .portfolio {
    &__title {
      font-weight: map-get($font-weight, bold);
    }
  }
}*/
.grid__column-title {
  font-size: 0.71428571rem;
}
.grid_theme_statistics .grid__column {
  font-size: 0.85714286rem;
}

.checkbox__multiply {
  font-size: 0.85714286rem;
}
@media (max-width: 767px) {
  .checkbox_text-tiny-sm .checkbox__label {
    font-size: 0.71428571rem;
  }
  .checkbox_text-base-sm .checkbox__label {
    font-size: 1rem;
  }
}
.checkbox .mobile-version_text-tiny-sm .checkbox__label {
  font-size: 0.71428571rem;
}
.checkbox .mobile-version_text-base-sm .checkbox__label {
  font-size: 1rem;
}
.checkbox ::ng-deep .mobile-version_text-tiny-sm .checkbox__label {
  font-size: 0.71428571rem;
}
.checkbox ::ng-deep .mobile-version_text-base-sm .checkbox__label {
  font-size: 1rem;
}

.dpp-pk-cycles__item-popover-content {
  font-size: 0.85714286rem;
}
.dpp-pk-cycles__star:after {
  font-size: 1.1428571429rem;
}

@media (max-width: 767px) {
  .dpp-pk-cycles__warning {
    font-size: 0.71428571rem;
  }
  .dpp-pk-cycles__item-popover-col:first-of-type {
    font-weight: 700;
  }
  .dpp-pk-cycles__star {
    font-size: 0.85714286rem;
  }
  .dpp-pk-popup-title {
    font-size: 0.85714286rem;
  }
}
.mobile-version .dpp-pk-cycles__warning {
  font-size: 0.71428571rem;
}
.mobile-version .dpp-pk-cycles__item-popover-col:first-of-type {
  font-weight: 700;
}
.mobile-version .dpp-pk-cycles__star {
  font-size: 0.85714286rem;
}
.mobile-version .dpp-pk-popup-title {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .dpp-pk-cycles__warning {
  font-size: 0.71428571rem;
}
::ng-deep .mobile-version .dpp-pk-cycles__item-popover-col:first-of-type {
  font-weight: 700;
}
::ng-deep .mobile-version .dpp-pk-cycles__star {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .dpp-pk-popup-title {
  font-size: 0.85714286rem;
}

.dpp-pk-my-cycles__grid-value {
  font-size: 1.1428571429rem;
}
.dpp-pk-my-cycles__grid-value_dates {
  font-weight: 600;
}
.dpp-pk-my-cycles__item-popover-content {
  font-size: 0.85714286rem;
}

/*@include tablet {
  .dpp-pk-my-cycles {
    &__grid-value {
      font-size: map-get($font-size, base);
    }
  }
}*/
@media (max-width: 767px) {
  .dpp-pk-my-cycles__grid-value {
    font-size: 1rem;
    font-weight: 600;
  }
}
.mobile-version .dpp-pk-my-cycles__grid-value {
  font-size: 1rem;
  font-weight: 600;
}

::ng-deep .mobile-version .dpp-pk-my-cycles__grid-value {
  font-size: 1rem;
  font-weight: 600;
}

.assessment-card__zet-label {
  font-weight: 600;
}

/* @include tablet {
   .assessment-card {
     &__result-label {
       font-size: map-get($font-size, base);
     }
     &__zet-label {
       font-size: map-get($font-size, base);
       font-weight: map-get($font-weight, regular);
     }

     &__link {
       font-size: map-get($font-size, small);
     }

     &__try-counter-text-tablet {
       font-size: map-get($font-size, small);
     }
   }
 }*/
@media (max-width: 767px) {
  .assessment-card__zet-label {
    font-weight: 500;
  }
}
.mobile-version .assessment-card__zet-label {
  font-weight: 500;
}

::ng-deep .mobile-version .assessment-card__zet-label {
  font-weight: 500;
}

@media (max-width: 767px) {
  .edu-trajectory-helper__beyond-topics-container-label {
    font-size: 1rem;
  }
}
.mobile-version .edu-trajectory-helper__beyond-topics-container-label {
  font-size: 1rem;
}

::ng-deep .mobile-version .edu-trajectory-helper__beyond-topics-container-label {
  font-size: 1rem;
}

.confirm-popup__title_minimal {
  font-size: 1.1428571429rem;
  font-weight: 500;
}

@media (max-width: 767px) {
  .psychological-tests-list__title {
    font-size: 1.2857142857rem;
    font-weight: 600;
  }
}
.mobile-version .psychological-tests-list__title {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

::ng-deep .mobile-version .psychological-tests-list__title {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .iom-main-info__subtitle {
    font-weight: 400;
  }
}
.mobile-version .iom-main-info__subtitle {
  font-weight: 400;
}

::ng-deep .mobile-version .iom-main-info__subtitle {
  font-weight: 400;
}

@media (min-width: 768px) and (max-width: 1333px) {
  .iom-main-info__start-btn {
    font-size: 0.85714286rem;
  }
}
@media (max-width: 767px) {
  .iom-extra-info__subtitle {
    font-weight: 600;
    font-size: 0.85714286rem;
  }
}
.mobile-version .iom-extra-info__subtitle {
  font-weight: 600;
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .iom-extra-info__subtitle {
  font-weight: 600;
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .om-main-info__subtitle {
    font-weight: 400;
  }
}
.mobile-version .om-main-info__subtitle {
  font-weight: 400;
}

::ng-deep .mobile-version .om-main-info__subtitle {
  font-weight: 400;
}

@media (max-width: 767px) {
  .om-extra-info__subtitle {
    font-weight: 600;
    font-size: 0.85714286rem;
  }
}
.mobile-version .om-extra-info__subtitle {
  font-weight: 600;
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .om-extra-info__subtitle {
  font-weight: 600;
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .prof-questionary-question__title {
    font-size: 1rem;
    font-weight: 600;
  }
}
.mobile-version .prof-questionary-question__title {
  font-size: 1rem;
  font-weight: 600;
}

::ng-deep .mobile-version .prof-questionary-question__title {
  font-size: 1rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .psy-questionary-question__title {
    font-size: 1rem;
    font-weight: 600;
  }
  .psy-questionary-question__previous-btn {
    font-weight: 600;
  }
}
.mobile-version .psy-questionary-question__title {
  font-size: 1rem;
  font-weight: 600;
}
.mobile-version .psy-questionary-question__previous-btn {
  font-weight: 600;
}

::ng-deep .mobile-version .psy-questionary-question__title {
  font-size: 1rem;
  font-weight: 600;
}
::ng-deep .mobile-version .psy-questionary-question__previous-btn {
  font-weight: 600;
}

@media (max-width: 767px) {
  .psy-test-card__title {
    font-size: 1.1428571429rem;
    font-weight: 600;
  }
  .psy-test-card__description {
    font-size: 0.85714286rem;
  }
  .psy-test-results-container__title {
    font-weight: 600;
  }
}
.mobile-version .psy-test-card__title {
  font-size: 1.1428571429rem;
  font-weight: 600;
}
.mobile-version .psy-test-card__description {
  font-size: 0.85714286rem;
}
.mobile-version .psy-test-results-container__title {
  font-weight: 600;
}

::ng-deep .mobile-version .psy-test-card__title {
  font-size: 1.1428571429rem;
  font-weight: 600;
}
::ng-deep .mobile-version .psy-test-card__description {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .psy-test-results-container__title {
  font-weight: 600;
}

@media (max-width: 767px) {
  .psychological-tests-list__title {
    font-size: 1.2857142857rem;
    font-weight: 600;
  }
}
.mobile-version .psychological-tests-list__title {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

::ng-deep .mobile-version .psychological-tests-list__title {
  font-size: 1.2857142857rem;
  font-weight: 600;
}

.questionary-result-item__number-of-points {
  font-size: 4rem;
}

@media (max-width: 767px) {
  .questionary-result-item__number-of-points {
    font-weight: 600;
    font-size: 3.4285714286rem;
  }
  .questionary-result-item__category {
    font-weight: 600;
    font-size: 1rem;
  }
  .questionary-result-item__interpretation {
    font-weight: 600;
    font-size: 1rem;
  }
}
.mobile-version .questionary-result-item__number-of-points {
  font-weight: 600;
  font-size: 3.4285714286rem;
}
.mobile-version .questionary-result-item__category {
  font-weight: 600;
  font-size: 1rem;
}
.mobile-version .questionary-result-item__interpretation {
  font-weight: 600;
  font-size: 1rem;
}

::ng-deep .mobile-version .questionary-result-item__number-of-points {
  font-weight: 600;
  font-size: 3.4285714286rem;
}
::ng-deep .mobile-version .questionary-result-item__category {
  font-weight: 600;
  font-size: 1rem;
}
::ng-deep .mobile-version .questionary-result-item__interpretation {
  font-weight: 600;
  font-size: 1rem;
}

@media (max-width: 767px) {
  .questionary-results__title {
    font-size: 0.71428571rem;
    font-weight: 600;
  }
}
.mobile-version .questionary-results__title {
  font-size: 0.71428571rem;
  font-weight: 600;
}

::ng-deep .mobile-version .questionary-results__title {
  font-size: 0.71428571rem;
  font-weight: 600;
}

@media (max-width: 767px) {
  .prof-questionary-question__previous-btn {
    font-weight: 600;
  }
}
.mobile-version .prof-questionary-question__previous-btn {
  font-weight: 600;
}

::ng-deep .mobile-version .prof-questionary-question__previous-btn {
  font-weight: 600;
}

.questionary-rewards__feedback-thanks-text {
  font-size: 3.4285714286rem;
}
.questionary-rewards__first-self-control-recomendation {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .questionary-rewards__btn-text {
    font-size: 0.9286rem;
  }
  .questionary-rewards__first-self-control-recomendation {
    font-size: 1.2857142857rem;
  }
}
.mobile-version .questionary-rewards__btn-text {
  font-size: 0.9286rem;
}
.mobile-version .questionary-rewards__first-self-control-recomendation {
  font-size: 1.2857142857rem;
}

::ng-deep .mobile-version .questionary-rewards__btn-text {
  font-size: 0.9286rem;
}
::ng-deep .mobile-version .questionary-rewards__first-self-control-recomendation {
  font-size: 1.2857142857rem;
}

.dpp-pk-popup__zet_number {
  font-size: 4rem;
}
@media (max-width: 767px) {
  .dpp-pk-popup__header-main-title {
    font-size: 1.2857142857rem;
  }
  .dpp-pk-popup__zet_number {
    font-size: 2.5714285714rem;
  }
  .dpp-pk-popup__header-date-range .text {
    font-weight: 600;
  }
  .dpp-pk-popup__header-main-title {
    font-weight: 600;
  }
  .dpp-pk-popup__cancel-btn {
    font-size: 1.2857142857rem;
  }
}
.mobile-version .dpp-pk-popup__header-main-title {
  font-size: 1.2857142857rem;
}
.mobile-version .dpp-pk-popup__zet_number {
  font-size: 2.5714285714rem;
}
.mobile-version .dpp-pk-popup__header-date-range .text {
  font-weight: 600;
}
.mobile-version .dpp-pk-popup__header-main-title {
  font-weight: 600;
}
.mobile-version .dpp-pk-popup__cancel-btn {
  font-size: 1.2857142857rem;
}

::ng-deep .mobile-version .dpp-pk-popup__header-main-title {
  font-size: 1.2857142857rem;
}
::ng-deep .mobile-version .dpp-pk-popup__zet_number {
  font-size: 2.5714285714rem;
}
::ng-deep .mobile-version .dpp-pk-popup__header-date-range .text {
  font-weight: 600;
}
::ng-deep .mobile-version .dpp-pk-popup__header-main-title {
  font-weight: 600;
}
::ng-deep .mobile-version .dpp-pk-popup__cancel-btn {
  font-size: 1.2857142857rem;
}

/*@include tablet {
  .dpp-pk-addresses {

    &__th {
      font-weight: map-get($font-weight, bold);
    }

    &__td, &__th {
      font-size: map-get($font-size, small);
    }
  }
}*/
@media (max-width: 767px) {
  .dpp-pk-addresses__th {
    font-weight: 600;
  }
  .dpp-pk-addresses__td, .dpp-pk-addresses__th {
    font-size: 0.85714286rem;
  }
}
.mobile-version .dpp-pk-addresses__th {
  font-weight: 600;
}
.mobile-version .dpp-pk-addresses__td, .mobile-version .dpp-pk-addresses__th {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .dpp-pk-addresses__th {
  font-weight: 600;
}
::ng-deep .mobile-version .dpp-pk-addresses__td, ::ng-deep .mobile-version .dpp-pk-addresses__th {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .search-wizard__content-item {
    font-size: 0.85714286rem;
  }
}
.mobile-version .search-wizard__content-item {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .search-wizard__content-item {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .edu-selected-cycle__above-col {
    font-size: 0.85714286rem;
  }
  .edu-selected-cycle__label {
    font-size: 0.85714286rem;
  }
  .edu-selected-cycle__link {
    font-size: 0.85714286rem;
  }
}
.mobile-version .edu-selected-cycle__above-col {
  font-size: 0.85714286rem;
}
.mobile-version .edu-selected-cycle__label {
  font-size: 0.85714286rem;
}
.mobile-version .edu-selected-cycle__link {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .edu-selected-cycle__above-col {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-selected-cycle__label {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-selected-cycle__link {
  font-size: 0.85714286rem;
}

.read-more__button {
  font-size: 0.85714286rem;
}

.multi-line-text_marked {
  font-weight: 600;
}

@media (max-width: 767px) {
  .multi-line-text_marked {
    font-size: 1rem;
  }
}
.mobile-version .multi-line-text_marked {
  font-size: 1rem;
}

::ng-deep .mobile-version .multi-line-text_marked {
  font-size: 1rem;
}

@media (max-width: 767px) {
  .edu-el-add__header-subtitle {
    font-size: 0.85714286rem;
  }
  .edu-el-add__confirmation-heading {
    font-size: 1.1428571429rem;
  }
}
.mobile-version .edu-el-add__header-subtitle {
  font-size: 0.85714286rem;
}
.mobile-version .edu-el-add__confirmation-heading {
  font-size: 1.1428571429rem;
}

::ng-deep .mobile-version .edu-el-add__header-subtitle {
  font-size: 0.85714286rem;
}
::ng-deep .mobile-version .edu-el-add__confirmation-heading {
  font-size: 1.1428571429rem;
}

.accordion__label {
  font-size: 1.2857142857rem;
}
.accordion__label .text {
  font-size: 1.2857142857rem;
}

/*@include tablet {
  .topics-title {

    &__text {
      font-size: map-get($font-size, base);
    }
  }
}*/
@media (max-width: 767px) {
  .accordion__label {
    font-size: 1rem;
  }
  .accordion__label .text {
    font-size: 1rem;
  }
}
.mobile-version .accordion__label {
  font-size: 1rem;
}
.mobile-version .accordion__label .text {
  font-size: 1rem;
}

::ng-deep .mobile-version .accordion__label {
  font-size: 1rem;
}
::ng-deep .mobile-version .accordion__label .text {
  font-size: 1rem;
}

.profile-awards__show-all-btn, .profile-awards__hide-all-btn {
  font-size: 0.85714286rem;
}

.frmr-certificates-confirmation__helper-msg-list-item._highlight {
  font-weight: 600;
}

/*@include tablet{
  .sidebar {
    &__title {
      font-size: map-get($font-size, base);
    }
    &__custom-link {
      font-size: map-get($font-size, base);
    }
  }
}*/
@media (max-width: 767px) {
  .sidebar__toggle-text {
    font-size: 0.85714286rem;
  }
}
.mobile-version .sidebar__toggle-text {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .sidebar__toggle-text {
  font-size: 0.85714286rem;
}

@media (max-width: 767px) {
  .om-addresses__td:first-of-type, .om-addresses__notes-title {
    font-weight: 600;
  }
  .om-addresses__td, .om-addresses__notes-text, .om-addresses__notes-title {
    font-size: 0.85714286rem;
  }
}
.mobile-version .om-addresses__td:first-of-type, .mobile-version .om-addresses__notes-title {
  font-weight: 600;
}
.mobile-version .om-addresses__td, .mobile-version .om-addresses__notes-text, .mobile-version .om-addresses__notes-title {
  font-size: 0.85714286rem;
}

::ng-deep .mobile-version .om-addresses__td:first-of-type, ::ng-deep .mobile-version .om-addresses__notes-title {
  font-weight: 600;
}
::ng-deep .mobile-version .om-addresses__td, ::ng-deep .mobile-version .om-addresses__notes-text, ::ng-deep .mobile-version .om-addresses__notes-title {
  font-size: 0.85714286rem;
}

.choose-document__item-subtitle, .choose-document__item-subtitle-value {
  font-size: 1.1428571429rem;
}

@media (max-width: 767px) {
  .choose-document__item-subtitle, .choose-document__item-subtitle-value {
    font-size: 1rem;
  }
}
.mobile-version .choose-document__item-subtitle, .mobile-version .choose-document__item-subtitle-value {
  font-size: 1rem;
}

::ng-deep .mobile-version .choose-document__item-subtitle, ::ng-deep .mobile-version .choose-document__item-subtitle-value {
  font-size: 1rem;
}

/*@include tablet {
  .outside-cycles {
    &__text {
      font-size: map-get($font-size, base);
    }
  }
}*/
.add-doc__tooltip {
  background: #ffffff;
}
.add-doc__loader .loader__stop {
  stop-color: #195ea0;
}

.accessible-version {
  color: #000000;
  background: #ffffff;
}
.accessible-version a:focus {
  outline: 0.1rem solid #000000;
}

::ng-deep .accessible-version .select__filter-clear-button:focus {
  background: #000000;
}
::ng-deep .accessible-version .select__filter-clear-button:focus .figure {
  color: #ffffff;
}

.accessible-version .select__filter-clear-button:focus {
  background: #000000;
}
.accessible-version .select__filter-clear-button:focus .figure {
  color: #ffffff;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    input, input:disabled {
      -webkit-text-fill-color: currentColor;
    }
    input::-webkit-input-placeholder, input:disabled::-webkit-input-placeholder {
      -webkit-text-fill-color: #b8b8b8;
    }
  }
}
div.hopscotch-bubble {
  background-color: #195ea0;
  border-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-bubble-number {
  background-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-nav-button.prev {
  background-color: #ffffff;
  border-color: #195ea0;
  color: #195ea0;
}
div.hopscotch-bubble .hopscotch-nav-button.next {
  background-color: #195ea0;
}
div.hopscotch-bubble .hopscotch-nav-button.next:hover {
  background-color: #124474;
}
div.hopscotch-bubble .hopscotch-bubble-container {
  background-color: #ffffff;
}
div.hopscotch-bubble .hopscotch-bubble-close:before, div.hopscotch-bubble .hopscotch-bubble-close:after {
  background: #8a8a8a;
}

@media (max-width: 767px) {
  div.hopscotch-bubble .hopscotch-bubble-close {
    background: #e7e7e7;
  }
  div.hopscotch-bubble .hopscotch-bubble-close:before, div.hopscotch-bubble .hopscotch-bubble-close:after {
    background: #434343;
  }
}
.mobile-version div.hopscotch-bubble .hopscotch-bubble-close {
  background: #e7e7e7;
}
.mobile-version div.hopscotch-bubble .hopscotch-bubble-close:before, .mobile-version div.hopscotch-bubble .hopscotch-bubble-close:after {
  background: #434343;
}

::ng-deep .mobile-version div.hopscotch-bubble .hopscotch-bubble-close {
  background: #e7e7e7;
}
::ng-deep .mobile-version div.hopscotch-bubble .hopscotch-bubble-close:before, ::ng-deep .mobile-version div.hopscotch-bubble .hopscotch-bubble-close:after {
  background: #434343;
}

.help-info blockquote {
  border-left: solid 0.3rem #bec2ca;
}

.content {
  color: #151515;
}

.dpp-pk-cycles__loader {
  background-color: #ffffff;
}
.dpp-pk-cycles__star:after {
  color: #0f3860;
}

.container {
  background: #ffffff;
  color: #000000;
}

.header {
  background-color: #e8eef5;
}
.header__nav_top {
  background-color: #e8eef5;
  border-bottom-color: #d0d0d0;
}
.header__item {
  color: #434343;
}
.header__item_active {
  color: #000000;
}
.header__item_permanently-active {
  color: #195ea0;
}
.header__link {
  color: inherit;
}
.header__link_with-nested:after {
  border-color: #d0d0d0;
}
.header__link_with-nested.header__link_drop-opened:after {
  border-color: #195ea0;
}
.header__link_active, .header__link_permanently-active, .header__link_dependent-route {
  color: #195ea0;
}
.header__link_active:before, .header__link_permanently-active:before, .header__link_dependent-route:before {
  color: #195ea0;
}
.header__sandwich-tablet-mobile {
  color: #195ea0;
}
.header__back-mobile {
  color: #195ea0;
  outline: none;
}
.header__section-tablet-mobile_line:before {
  background: #e7e7e7;
}
.header__tablet-mobile-submenu-sidebar {
  border-top-color: #e7e7e7;
  border-bottom-color: #e7e7e7;
}

/*@include tablet {
  .header {

    &__section-tablet-mobile {
      &_profile {
        background-color: map-get($mono, tint02);
      }
    }

  }
}*/
@media (max-width: 767px) {
  .header__section-tablet-mobile_profile {
    background-color: #f7f7f7;
  }
  .header__mobile-submenu-back {
    color: #195ea0;
  }
  .header__tablet-mobile-submenu {
    background-color: #e8eef5;
  }
}
.mobile-version .header__section-tablet-mobile_profile {
  background-color: #f7f7f7;
}
.mobile-version .header__mobile-submenu-back {
  color: #195ea0;
}
.mobile-version .header__tablet-mobile-submenu {
  background-color: #e8eef5;
}

::ng-deep .mobile-version .header__section-tablet-mobile_profile {
  background-color: #f7f7f7;
}
::ng-deep .mobile-version .header__mobile-submenu-back {
  color: #195ea0;
}
::ng-deep .mobile-version .header__tablet-mobile-submenu {
  background-color: #e8eef5;
}

::ng-deep .accessible-version .header__section-tablet-mobile_profile {
  background-color: #ffffff;
}
::ng-deep .accessible-version .header__back-mobile:focus {
  outline: 0.1rem solid currentColor;
}
::ng-deep .accessible-version .header__sandwich-mobile {
  margin-left: auto;
}
::ng-deep .accessible-version .header__sandwich-mobile:focus {
  background-color: #000000;
}
::ng-deep .accessible-version .header__sandwich-mobile:focus .figure {
  color: #ffffff;
}
::ng-deep .accessible-version .header__link_with-nested:after {
  border-color: #000000;
}

.accessible-version .header__section-tablet-mobile_profile {
  background-color: #ffffff;
}
.accessible-version .header__back-mobile:focus {
  outline: 0.1rem solid currentColor;
}
.accessible-version .header__sandwich-mobile {
  margin-left: auto;
}
.accessible-version .header__sandwich-mobile:focus {
  background-color: #000000;
}
.accessible-version .header__sandwich-mobile:focus .figure {
  color: #ffffff;
}
.accessible-version .header__link_with-nested:after {
  border-color: #000000;
}

.popover__body_arrow_top:after {
  border-color: transparent transparent #ffffff transparent;
}
.popover__body_arrow_bottom:after {
  border-color: #ffffff transparent transparent transparent;
}
.popover__content {
  background: #ffffff;
}

@media (max-width: 767px) {
  .popover_theme_mobile-fullscreen {
    background: rgba(0, 0, 0, 0.1);
  }
}
.mobile-version .popover_theme_mobile-fullscreen {
  background: rgba(0, 0, 0, 0.1);
}

::ng-deep .mobile-version .popover_theme_mobile-fullscreen {
  background: rgba(0, 0, 0, 0.1);
}

.popup__container_accent {
  border-top-color: #195ea0;
}

.tooltip {
  background: #195ea0;
  color: #ffffff;
}
.tooltip:after {
  border-top-color: #195ea0 !important;
}
.tooltip_white {
  color: #000000;
  background: #ffffff;
}
.tooltip_white:after {
  border-top-color: #ffffff;
}

.progress-bar__line {
  background: #e0e8f1;
}
.progress-bar__planned {
  background: #b8dcbe;
}
.progress-bar__completed {
  background: #00B740;
}

.border-separator-light {
  border-color: #f3f3f3;
}

.border-separator-primary {
  border-color: #d9dce4;
}

.like-dislike__icon {
  color: #bec2ca;
}
.like-dislike__icon:hover {
  color: #a1a1a1;
}
.like-dislike__icon_active, .like-dislike__icon_active:hover {
  color: #195ea0;
}
.like-dislike__icon_active-dislike {
  color: #8a8a8a;
}
.like-dislike__icon_active-dislike:hover {
  color: #195ea0;
}
.like-dislike__text {
  color: #8a8a8a;
}

::ng-deep .accessible-version .like-dislike__button:focus {
  outline: 0.1rem solid currentColor;
  outline-offset: 0.15rem;
}

.accessible-version .like-dislike__button:focus {
  outline: 0.1rem solid currentColor;
  outline-offset: 0.15rem;
}

.error-notification {
  background-color: #FF728E;
}

::ng-deep .accessible-version .error-notification {
  color: #ffffff;
}

.accessible-version .error-notification {
  color: #ffffff;
}

.edu-element-view-title__competence {
  color: #F7B44A;
}
.edu-element-view-title__sub-title {
  color: #b8b8b8;
}
.edu-element-view-title__feedback-title {
  color: #195ea0;
}
.edu-element-view-title_leave-feedback {
  color: #195ea0;
}
.edu-element-view-title__status {
  color: #8a8a8a;
}
.edu-element-view-title__status_primary {
  color: #195ea0;
}
.edu-element-view-title__status_success {
  color: #00B740;
}
.edu-element-view-title__status_accent {
  color: #F7B44A;
}
.edu-element-view-title__status_no-access, .edu-element-view-title__status_canceled {
  color: #d84826;
}
.edu-element-view-title__notification {
  color: #727272;
  background-color: #ffffff;
  border: 0.25rem solid #a1a1a1;
}
.edu-element-view-title__status-badge {
  color: inherit;
}
.edu-element-view-title__status-text {
  color: #000000;
}

@media (max-width: 767px) {
  .edu-element-view-title__status-text {
    color: inherit;
  }
}
.mobile-version .edu-element-view-title__status-text {
  color: inherit;
}

::ng-deep .mobile-version .edu-element-view-title__status-text {
  color: inherit;
}

.range-slider > .ngx-slider .ngx-slider-bar {
  background: #d9dce4;
}
.range-slider > .ngx-slider .ngx-slider-selection {
  background: #195ea0;
}
.range-slider > .ngx-slider > .ngx-slider-pointer {
  background: #165490;
}

.text {
  color: #000000;
}
.text_primary {
  color: #195ea0;
}
.text_primary-mono {
  color: #151515;
}
.text_primary-light {
  color: #165490;
}
.text_primary-dark {
  color: #0f3860;
}
.text_alert {
  color: #FF728E;
}
.text_alert-dark {
  color: #e5667f;
}
.text_accent {
  color: #F7B44A;
}
.text_mono-dark {
  color: #5b5b5b;
}
.text_mono-light {
  color: #a1a1a1;
}
.text_contrast {
  color: #ffffff;
}
.text_muted-dark {
  color: #2c2c2c;
}
.text_muted {
  color: #5b5b5b;
}
.text_muted-light {
  color: #8a8a8a;
}
.text_muted-lighter {
  color: #a1a1a1;
}
.text_muted-lightest {
  color: #dedee1;
}
.text_baselight-80 {
  color: #727272;
}
.text_success {
  color: #00B740;
}

.heading__secondary {
  color: #8a8a8a;
}

.text-field {
  background: #ffffff;
  color: #151515;
  border-color: #dedee1;
}
.text-field::placeholder, .text-field_placeholder {
  color: #b8b8b8;
}
.text-field:-ms-input-placeholder {
  color: #b8b8b8;
}
.text-field:focus, .text-field__active {
  background: #ffffff;
  border-color: #195ea0;
}
.text-field.disabled:not(.text-field_ignore-disabled), .text-field[disabled]:not(.text-field_ignore-disabled) {
  border-color: #dedee1;
  color: #8a8a8a;
}
.text-field.ng-invalid.ng-dirty {
  background-color: #fff0f3;
}
.text-field.ng-invalid.ng-dirty::placeholder {
  color: #b8b8b8;
}
.text-field_transparent, .text-field_transparent:focus, .text-field_transparent__active {
  background: none;
}

.error-message {
  background-color: #BD362F;
}
.error-message_color_alert {
  color: #ffffff;
}

.form-control {
  color: #000000;
}
.form-control__help {
  color: #195ea0;
}
.form-control__required-mark {
  color: #195ea0;
}

.form-control-tooltip {
  background: #ffffff;
}

.toggle__chevron:before, .toggle__chevron:after {
  background: #727272;
}

::ng-deep .accessible-version .toggle__chevron:before, ::ng-deep .accessible-version .toggle__chevron:after {
  background: #000000;
}

.accessible-version .toggle__chevron:before, .accessible-version .toggle__chevron:after {
  background: #000000;
}

.collapse-toggle {
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.collapse-toggle__icon {
  color: #8a8a8a;
}

.autocomplete-host.ng-invalid.ng-dirty .text-field {
  background-color: #fff0f3;
}
.autocomplete-host.ng-invalid.ng-dirty .text-field::placeholder {
  color: #b8b8b8;
}

.autocomplete__dropdown-menu, .autocomplete__button {
  background: #ffffff;
  color: #151515;
  text-align: left;
}
.autocomplete__dropdown-menu:hover, .autocomplete__button:hover {
  background: #467eb3;
  color: #ffffff;
}
.autocomplete__dropdown-menu_active, .autocomplete__button_active {
  background: #195ea0;
  color: #ffffff;
}
.autocomplete__dropdown-menu_empty, .autocomplete__button_empty {
  color: #b8b8b8;
}
.autocomplete__group-name {
  background: #ffffff;
  color: #a1a1a1;
}
.autocomplete__group-name:hover {
  background: #ffffff;
  color: #a1a1a1;
}
.autocomplete__group-separator {
  background-color: #d0d0d0;
}
.autocomplete_theme_cycles .text-field {
  background: #195ea0 !important;
  color: #ffffff !important;
  -webkit-text-fill-color: #ffffff !important;
  opacity: 1 !important;
}
.autocomplete_theme_cycles .text-field::placeholder, .autocomplete_theme_cycles .text-field_placeholder {
  color: #ffffff !important;
}
.autocomplete_theme_cycles .toggle__chevron:before, .autocomplete_theme_cycles .toggle__chevron:after {
  background: #ffffff;
}
.autocomplete_theme_statistics .form-control {
  color: #727272;
}

::ng-deep .accessible-version .autocomplete__dropdown-menu {
  border: 0.1rem solid #000000;
  border-radius: 0.1481481481rem;
}
::ng-deep .accessible-version .autocomplete__input_contrast {
  text-decoration: underline;
}

.accessible-version .autocomplete__dropdown-menu {
  border: 0.1rem solid #000000;
  border-radius: 0.1481481481rem;
}
.accessible-version .autocomplete__input_contrast {
  text-decoration: underline;
}

@media (max-width: 767px) {
  .autocomplete__overlay {
    background: #ffffff;
  }
  .autocomplete__dropdown-item {
    border-bottom: 0.1rem solid #e7e7e7;
  }
  .autocomplete__filter-clear-button:focus {
    background: #000000;
  }
  .autocomplete__filter-clear-button:focus .figure {
    color: #ffffff;
  }
}
.mobile-version .autocomplete__overlay {
  background: #ffffff;
}
.mobile-version .autocomplete__dropdown-item {
  border-bottom: 0.1rem solid #e7e7e7;
}
.mobile-version .autocomplete__filter-clear-button:focus {
  background: #000000;
}
.mobile-version .autocomplete__filter-clear-button:focus .figure {
  color: #ffffff;
}

::ng-deep .mobile-version .autocomplete__overlay {
  background: #ffffff;
}
::ng-deep .mobile-version .autocomplete__dropdown-item {
  border-bottom: 0.1rem solid #e7e7e7;
}
::ng-deep .mobile-version .autocomplete__filter-clear-button:focus {
  background: #000000;
}
::ng-deep .mobile-version .autocomplete__filter-clear-button:focus .figure {
  color: #ffffff;
}

.checkbox {
  color: #151515;
}
.checkbox:focus {
  outline-color: #a3bed9;
}
.checkbox__multiply {
  background-color: #00B740;
  color: #ffffff;
}
.checkbox_accent .checkbox__styled:after {
  border-color: #F7B44A;
}
.checkbox_primary .checkbox__styled:after {
  border-color: #195ea0;
}
.checkbox_success .checkbox__styled:after {
  border-color: #00B740;
}
.checkbox_theme_disabled-select .checkbox__input:disabled + .checkbox__styled {
  background: #f9fafd;
  border-color: #e7e7e7;
}
.checkbox_theme_disabled-select .checkbox__input:disabled + .checkbox__styled:after {
  border-color: #F7B44A;
}

.checkbox__styled {
  background: #f9fafd;
  border-color: #b8b8b8;
}
.checkbox__styled:after {
  border-color: #151515;
}

.checkbox__input:disabled + .checkbox__styled {
  background: #f9fafd;
  border-color: #e7e7e7;
}
.checkbox__input:disabled + .checkbox__styled:after {
  border-color: #b8b8b8;
}

.chart-card {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .chart-card {
    box-shadow: none;
    border-bottom: 1px solid #d0d0d0;
  }
  .chart-card:last-of-type {
    border-bottom: none;
  }
}
.mobile-version .chart-card {
  box-shadow: none;
  border-bottom: 1px solid #d0d0d0;
}
.mobile-version .chart-card:last-of-type {
  border-bottom: none;
}

::ng-deep .mobile-version .chart-card {
  box-shadow: none;
  border-bottom: 1px solid #d0d0d0;
}
::ng-deep .mobile-version .chart-card:last-of-type {
  border-bottom: none;
}

.radio {
  color: #151515;
}
.radio__input:disabled + .radio__icon:after {
  background: #b8b8b8;
}
.radio__icon:before {
  border-color: #b8b8b8;
  background: #f9fafd;
}
.radio__icon:after {
  background: #151515;
}
.radio__icon_accent:after {
  background: #F7B44A;
}
.radio__icon_success:after {
  background: #00B740;
}
.radio__icon_primary:after {
  background: #195ea0;
}

.button_primary {
  background-color: #195ea0;
  color: #ffffff;
  border-color: #195ea0;
}
.button_primary:hover:not(.button_stroked):not(.button_text) {
  background-color: #165490;
  color: #ffffff;
  border-color: #165490;
}
@media (max-width: 767px) {
  .button_primary:hover {
    background-color: #165490;
    color: #ffffff;
    border-color: #165490;
  }
}
.button_primary .mobile-version:hover {
  background-color: #165490;
  color: #ffffff;
  border-color: #165490;
}
.button_primary ::ng-deep .mobile-version:hover {
  background-color: #165490;
  color: #ffffff;
  border-color: #165490;
}
.button_accent {
  background-color: #00B740;
  color: #ffffff;
  border-color: #00B740;
}
.button_accent:hover:not(.button_stroked):not(.button_text) {
  background-color: #00a53a;
  color: #ffffff;
  border-color: #00a53a;
}
@media (max-width: 767px) {
  .button_accent:hover {
    background-color: #00a53a;
    color: #ffffff;
    border-color: #00a53a;
  }
}
.button_accent .mobile-version:hover {
  background-color: #00a53a;
  color: #ffffff;
  border-color: #00a53a;
}
.button_accent ::ng-deep .mobile-version:hover {
  background-color: #00a53a;
  color: #ffffff;
  border-color: #00a53a;
}
.button_warn {
  background-color: #F7B44A;
  color: #ffffff;
  border-color: #F7B44A;
}
.button_warn:hover:not(.button_stroked):not(.button_text) {
  background-color: #dea242;
  color: #ffffff;
  border-color: #dea242;
}
@media (max-width: 767px) {
  .button_warn:hover {
    background-color: #dea242;
    color: #ffffff;
    border-color: #dea242;
  }
}
.button_warn .mobile-version:hover {
  background-color: #dea242;
  color: #ffffff;
  border-color: #dea242;
}
.button_warn ::ng-deep .mobile-version:hover {
  background-color: #dea242;
  color: #ffffff;
  border-color: #dea242;
}
.button_warn-shade, .search__common-programs-filter .button:disabled {
  background-color: #ff7700;
  color: #ffffff;
  border-color: #ff7700;
}
.button_warn-shade:hover:not(.button_stroked):not(.button_text), .search__common-programs-filter .button:hover:not(.button_stroked):not(.button_text):disabled {
  background-color: #fc9d49;
  color: #ffffff;
  border-color: #fc9d49;
}
@media (max-width: 767px) {
  .button_warn-shade:hover, .search__common-programs-filter .button:hover:disabled {
    background-color: #fc9d49;
    color: #ffffff;
    border-color: #fc9d49;
  }
}
.button_warn-shade .mobile-version:hover, .search__common-programs-filter .button:disabled .mobile-version:hover {
  background-color: #fc9d49;
  color: #ffffff;
  border-color: #fc9d49;
}
.button_warn-shade ::ng-deep .mobile-version:hover, .search__common-programs-filter .button:disabled ::ng-deep .mobile-version:hover {
  background-color: #fc9d49;
  color: #ffffff;
  border-color: #fc9d49;
}
.button_alert {
  background-color: #FF728E;
  color: #ffffff;
  border-color: #FF728E;
}
.button_alert:hover:not(.button_stroked):not(.button_text) {
  background-color: #e5667f;
  color: #ffffff;
  border-color: #e5667f;
}
@media (max-width: 767px) {
  .button_alert:hover {
    background-color: #e5667f;
    color: #ffffff;
    border-color: #e5667f;
  }
}
.button_alert .mobile-version:hover {
  background-color: #e5667f;
  color: #ffffff;
  border-color: #e5667f;
}
.button_alert ::ng-deep .mobile-version:hover {
  background-color: #e5667f;
  color: #ffffff;
  border-color: #e5667f;
}
.button_basic {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
.button_basic:hover:not(.button_stroked):not(.button_text) {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
@media (max-width: 767px) {
  .button_basic:hover {
    background-color: #000000;
    color: #ffffff;
    border-color: #000000;
  }
}
.button_basic .mobile-version:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
.button_basic ::ng-deep .mobile-version:hover {
  background-color: #000000;
  color: #ffffff;
  border-color: #000000;
}
.button_contrast {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
.button_contrast:hover:not(.button_stroked):not(.button_text) {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
@media (max-width: 767px) {
  .button_contrast:hover {
    background-color: #ffffff;
    color: #000000;
    border-color: #ffffff;
  }
}
.button_contrast .mobile-version:hover {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
.button_contrast ::ng-deep .mobile-version:hover {
  background-color: #ffffff;
  color: #000000;
  border-color: #ffffff;
}
.button_gray {
  color: #ffffff;
  background: #d0d0d0;
}
.button_gray:hover {
  background: #b8b8b8;
}
.button:disabled {
  background-color: #f3f3f3;
  color: #a1a1a1;
  border-color: #f3f3f3;
}
.button:disabled:hover:not(.button_stroked):not(.button_text) {
  background-color: #f3f3f3;
  color: #a1a1a1;
  border-color: #f3f3f3;
}
@media (max-width: 767px) {
  .button:disabled:hover {
    background-color: #f3f3f3;
    color: #a1a1a1;
    border-color: #f3f3f3;
  }
}
.button:disabled .mobile-version:hover {
  background-color: #f3f3f3;
  color: #a1a1a1;
  border-color: #f3f3f3;
}
.button:disabled ::ng-deep .mobile-version:hover {
  background-color: #f3f3f3;
  color: #a1a1a1;
  border-color: #f3f3f3;
}
.button_flat {
  color: #195ea0;
}

::ng-deep .accessible-version .button:focus {
  text-decoration: underline;
}

.accessible-version .button:focus {
  text-decoration: underline;
}

.button_stroked {
  border-width: 0.09rem;
  border-style: solid;
  border-radius: 0.2rem;
  background: none;
}
.button_stroked:hover {
  background: none;
}
.button_stroked.button_primary {
  background-color: transparent;
  color: #195ea0;
  border-color: #195ea0;
}
.button_stroked.button_primary:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #165490;
  border-color: #165490;
}
@media (max-width: 767px) {
  .button_stroked.button_primary:hover {
    background-color: transparent;
    color: #165490;
    border-color: #165490;
  }
}
.button_stroked.button_primary .mobile-version:hover {
  background-color: transparent;
  color: #165490;
  border-color: #165490;
}
.button_stroked.button_primary ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #165490;
  border-color: #165490;
}
.button_stroked.button_accent {
  background-color: transparent;
  color: #00B740;
  border-color: #00B740;
}
.button_stroked.button_accent:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #00a53a;
  border-color: #00a53a;
}
@media (max-width: 767px) {
  .button_stroked.button_accent:hover {
    background-color: transparent;
    color: #00a53a;
    border-color: #00a53a;
  }
}
.button_stroked.button_accent .mobile-version:hover {
  background-color: transparent;
  color: #00a53a;
  border-color: #00a53a;
}
.button_stroked.button_accent ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #00a53a;
  border-color: #00a53a;
}
.button_stroked.button_alert {
  background-color: transparent;
  color: #FF728E;
  border-color: #FF728E;
}
.button_stroked.button_alert:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #e5667f;
  border-color: #e5667f;
}
@media (max-width: 767px) {
  .button_stroked.button_alert:hover {
    background-color: transparent;
    color: #e5667f;
    border-color: #e5667f;
  }
}
.button_stroked.button_alert .mobile-version:hover {
  background-color: transparent;
  color: #e5667f;
  border-color: #e5667f;
}
.button_stroked.button_alert ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #e5667f;
  border-color: #e5667f;
}
.button_stroked.button_warn {
  background-color: transparent;
  color: #F7B44A;
  border-color: #F7B44A;
}
.button_stroked.button_warn:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #dea242;
  border-color: #dea242;
}
@media (max-width: 767px) {
  .button_stroked.button_warn:hover {
    background-color: transparent;
    color: #dea242;
    border-color: #dea242;
  }
}
.button_stroked.button_warn .mobile-version:hover {
  background-color: transparent;
  color: #dea242;
  border-color: #dea242;
}
.button_stroked.button_warn ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #dea242;
  border-color: #dea242;
}
.button_stroked.button_mono {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}
.button_stroked.button_mono:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #a1a1a1;
  border-color: #a1a1a1;
}
@media (max-width: 767px) {
  .button_stroked.button_mono:hover {
    background-color: transparent;
    color: #a1a1a1;
    border-color: #a1a1a1;
  }
}
.button_stroked.button_mono .mobile-version:hover {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}
.button_stroked.button_mono ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}
.button_stroked.button_basic {
  background-color: transparent;
  color: #000000;
  border-color: #000000;
}
.button_stroked.button_basic:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #000000;
  border-color: #000000;
}
@media (max-width: 767px) {
  .button_stroked.button_basic:hover {
    background-color: transparent;
    color: #000000;
    border-color: #000000;
  }
}
.button_stroked.button_basic .mobile-version:hover {
  background-color: transparent;
  color: #000000;
  border-color: #000000;
}
.button_stroked.button_basic ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #000000;
  border-color: #000000;
}
.button_stroked.button_contrast {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.button_stroked.button_contrast:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #ffffff;
  border-color: #ffffff;
}
@media (max-width: 767px) {
  .button_stroked.button_contrast:hover {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }
}
.button_stroked.button_contrast .mobile-version:hover {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.button_stroked.button_contrast ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.button_stroked:disabled {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}
.button_stroked:disabled:hover:not(.button_stroked):not(.button_text) {
  background-color: rgba(0, 0, 0, 0.1);
  color: #a1a1a1;
  border-color: #a1a1a1;
}
@media (max-width: 767px) {
  .button_stroked:disabled:hover {
    background-color: transparent;
    color: #a1a1a1;
    border-color: #a1a1a1;
  }
}
.button_stroked:disabled .mobile-version:hover {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}
.button_stroked:disabled ::ng-deep .mobile-version:hover {
  background-color: transparent;
  color: #a1a1a1;
  border-color: #a1a1a1;
}

.button_arrow:after {
  border-left-color: #195ea0;
}
.button_arrow:hover:after {
  border-left-color: #165490;
}

.button_text, .button_text:hover, .button_text:disabled {
  background: none;
}
.button_text.button_primary {
  color: #195ea0;
}
.button_text.button_accent {
  color: #00B740;
}
.button_text.button_alert {
  color: #FF728E;
}
.button_text.button_warn {
  color: #F7B44A;
}
.button_text.button_success {
  color: #00B740;
}
.button_text.button_basic {
  color: #000000;
}
.button_text.button_mono {
  color: #a1a1a1;
}
.button_text.button_contrast {
  color: #ffffff;
}
.button_text:disabled {
  color: #a1a1a1;
}
.button_text:disabled:hover {
  color: #a1a1a1;
}
.button_iom-btn {
  width: 14.0714rem;
  height: 2.7857rem;
  padding: 0;
}

.icon_accent {
  color: #F7B44A;
}
.icon_delete {
  color: #2e3235;
}
.icon_alert {
  color: #FF728E;
}
.icon_black {
  color: #000000;
}
.icon_checked {
  color: #ff7700;
}
.icon_free {
  color: #8BC888;
}
.icon_gift-open-luminous {
  color: #FAC04A;
}
.icon_gift-open-luminous .luminous-stroke {
  stroke: #DFE3E9;
}
.icon_gray {
  color: #8a8a8a;
}
.icon_green {
  color: #00B740;
}
.icon_logo {
  color: #0a4d8d;
}
.icon_logo .main-stop {
  stop-color: #FF7700;
}
.icon_logo .alt-stop {
  stop-color: #FA0000;
}
.icon_mono {
  color: #5b5b5b;
}
.icon_paid {
  color: #FAB44A;
}
.icon_paid .background-fill {
  fill: #f9fafd;
}
.icon_paid .background-stroke {
  stroke: #f9fafd;
}
.icon_play-muted {
  color: #8a8a8a;
}
.icon_primary {
  color: #195ea0;
}
.icon_primary-dark {
  color: #0a4d8d;
}
.icon_purple {
  color: #0c2f50;
}
.icon_star {
  color: #F5A623;
}
.icon_light-gray {
  color: #bec2ca;
}
.icon_success {
  color: #00B740;
}
.icon_white {
  color: #ffffff;
}

.figure_chevron-left {
  border-color: #ffffff;
}
.figure_chevron-left_primary {
  border-color: #195ea0;
}
.figure_help {
  border-color: #195ea0;
}
.figure_help:before {
  color: #195ea0;
}
.figure_circle {
  border-color: #F7B44A;
  background-color: #F7B44A;
}
.figure_circle_green {
  border-color: #00B740;
  background-color: #00B740;
}
.figure_circle_primary {
  border-color: #195ea0;
  background-color: #195ea0;
}
.figure_circle_mono {
  border-color: #8a8a8a;
  background-color: #8a8a8a;
}
.figure_circle_success {
  border-color: #00B740;
  background-color: #00B740;
}
.figure_circle_accent {
  border-color: #F7B44A;
  background-color: #F7B44A;
}
.figure_circle_no-access {
  border-color: #d84826;
  background-color: #d84826;
}
.figure_checkmark {
  border-color: #00B740;
}
.figure_checkmark-small {
  border-color: #00B740;
}
.figure_dash_green {
  background: #00B740;
}
.figure_dash_red {
  background: #FF728E;
}
.figure_plus.figure_primary {
  background-color: #195ea0;
}
.figure_plus.figure_primary:before {
  border-color: #ffffff;
}
.figure_plus.figure_primary:after {
  border-color: #ffffff;
}
.figure_plus.figure_current_lines:before, .figure_plus.figure_current_lines:after {
  border-color: currentColor;
}
.figure_plus.figure_contrast {
  background-color: #ffffff;
}
.figure_plus.figure_contrast:before {
  border-color: #F7B44A;
}
.figure_plus.figure_contrast:after {
  border-color: #F7B44A;
}
.figure_minus.figure_basic {
  background-color: #ffffff;
}
.figure_minus.figure_basic:before {
  border-color: #000000;
}
.figure_minus.figure_basic:after {
  border-color: #000000;
}
.figure_more {
  color: #195ea0;
}
.figure_close, .figure_close-sm, .figure_close-xsm {
  color: #8a8a8a;
}
.figure_close:hover, .figure_close-sm:hover, .figure_close-xsm:hover {
  color: #434343;
}
.figure_close:before, .figure_close:after, .figure_close-sm:before, .figure_close-sm:after, .figure_close-xsm:before, .figure_close-xsm:after {
  background-color: currentColor;
}
.figure_close_primary:before, .figure_close_primary:after, .figure_close-sm_primary:before, .figure_close-sm_primary:after, .figure_close-xsm_primary:before, .figure_close-xsm_primary:after {
  background-color: #195ea0;
}
.figure_close_primary:hover:before, .figure_close_primary:hover:after, .figure_close-sm_primary:hover:before, .figure_close-sm_primary:hover:after, .figure_close-xsm_primary:hover:before, .figure_close-xsm_primary:hover:after {
  background-color: #467eb3;
}
.figure_close_contrast, .figure_close-sm_contrast, .figure_close-xsm_contrast {
  color: #ffffff;
}
.figure_cross:before, .figure_cross:after {
  background-color: #a1a1a1;
}
.figure_cross-big:before, .figure_cross-big:after {
  background-color: #a1a1a1;
}
.figure_cross-big_alert:before, .figure_cross-big_alert:after {
  background-color: #FF728E;
}
.figure_primary {
  color: #195ea0;
}

.flex-grid__sidebar {
  background: #ffffff;
}

.root {
  background: #f9fafd;
}
.root_light {
  background-color: #ffffff;
  color: #151515;
}

/*@include tablet {
  .root {
    &_light {
      background: map-get($custom, radio);
    }
  }
}*/
@media (max-width: 767px) {
  .root_light {
    background: #f9fafd;
  }
}
.mobile-version .root_light {
  background: #f9fafd;
}

::ng-deep .mobile-version .root_light {
  background: #f9fafd;
}

.profile-main {
  background-color: #f9fafd;
}
.profile-main__overlay-camera-icon {
  stroke: #f7f7f7;
}
.profile-main__overlay {
  color: #f7f7f7;
  background-color: #2e3235;
}
.profile-main__photo-container {
  background-color: #e7e7e7;
}
.profile-main__info-field_snils {
  color: #a1a1a1;
}
.profile-main__info-field_age {
  color: #a1a1a1;
}
.profile-main__info-field_registrationDate {
  color: #a1a1a1;
}
.profile-main__details-container {
  background-color: #ffffff;
}
.profile-main__navigation {
  background-color: #f9fafd;
}
.profile-main__navigation-link {
  color: #2e3235;
}
.profile-main__navigation-link_active {
  background: #ffffff;
  color: #195ea0;
}
.profile-main__navigation-mobile-wrap {
  background: #ffffff;
}
.profile-main__label {
  outline: none;
}

@media (max-width: 767px) {
  .profile-main__photo-container {
    background-color: #f7f7f7;
  }
  .profile-main__label_mobile:before {
    color: #ffffff;
    background: #195ea0;
    z-index: 3;
  }
  .profile-main__photo-container {
    border-color: #195ea0;
  }
  .profile-main__camera-icon {
    color: #195ea0;
  }
  .profile-main__photo-edit-icon {
    background: #195ea0;
    color: #ffffff;
  }
}
.mobile-version .profile-main__photo-container {
  background-color: #f7f7f7;
}
.mobile-version .profile-main__label_mobile:before {
  color: #ffffff;
  background: #195ea0;
  z-index: 3;
}
.mobile-version .profile-main__photo-container {
  border-color: #195ea0;
}
.mobile-version .profile-main__camera-icon {
  color: #195ea0;
}
.mobile-version .profile-main__photo-edit-icon {
  background: #195ea0;
  color: #ffffff;
}

::ng-deep .mobile-version .profile-main__photo-container {
  background-color: #f7f7f7;
}
::ng-deep .mobile-version .profile-main__label_mobile:before {
  color: #ffffff;
  background: #195ea0;
  z-index: 3;
}
::ng-deep .mobile-version .profile-main__photo-container {
  border-color: #195ea0;
}
::ng-deep .mobile-version .profile-main__camera-icon {
  color: #195ea0;
}
::ng-deep .mobile-version .profile-main__photo-edit-icon {
  background: #195ea0;
  color: #ffffff;
}

::ng-deep .accessible-version .profile-main__label:focus {
  outline: 0.1rem dashed currentColor;
  outline-offset: 0.15rem;
}

.accessible-version .profile-main__label:focus {
  outline: 0.1rem dashed currentColor;
  outline-offset: 0.15rem;
}

.switch-button__label:before {
  background-color: #bec2ca;
  border-color: #bec2ca;
}
.switch-button__label:after {
  background-color: #ffffff;
}
.switch-button__input_checked + .switch-button__label:before {
  background-color: #F7B44A;
  border-color: #F7B44A;
}

@media (max-width: 767px) {
  .switch-button__input_checked + .switch-button__label:before {
    background-color: #00B740;
    border-color: #00B740;
  }
}
.mobile-version .switch-button__input_checked + .switch-button__label:before {
  background-color: #00B740;
  border-color: #00B740;
}

::ng-deep .mobile-version .switch-button__input_checked + .switch-button__label:before {
  background-color: #00B740;
  border-color: #00B740;
}

.om-extra-info__item {
  border-top-color: #d0d0d0;
}

@media (max-width: 767px) {
  .om-extra-info__item {
    border-top-color: #d0d0d0;
  }
}
.mobile-version .om-extra-info__item {
  border-top-color: #d0d0d0;
}

::ng-deep .mobile-version .om-extra-info__item {
  border-top-color: #d0d0d0;
}

.sidebar {
  background: #e8eef5;
  color: #151515;
}
.sidebar__item_bordered {
  border-top-color: #e7e7e7;
}
.sidebar__item_separator-top {
  border-top: 0.0714285714rem solid #e7e7e7;
}
.sidebar__custom-link {
  color: #151515;
}
.sidebar__custom-link_active {
  color: #195ea0;
  border-left-color: #195ea0;
}
.sidebar__toggle-text {
  color: #195ea0;
}

@media (max-width: 767px) {
  .sidebar_popup-mobile {
    background: #f9fafd;
  }
}
.mobile-version .sidebar_popup-mobile {
  background: #f9fafd;
}

::ng-deep .mobile-version .sidebar_popup-mobile {
  background: #f9fafd;
}

.styling__loader .loader__stop {
  stop-color: #195ea0;
}
.styling__loader .loader__text {
  fill: #8a8a8a;
}
.styling__loader .loader__label {
  color: #8a8a8a;
}

.styling__loader_red .loader__stop {
  stop-color: #FF728E;
}
.styling__loader_red .loader__text {
  fill: #F7B44A;
}
.styling__loader_red .loader__label {
  color: #F7B44A;
}

.styling__loader_muted .loader__stop {
  stop-color: #bec2ca;
}
.styling__loader_muted .loader__text {
  fill: #bec2ca;
}
.styling__loader_muted .loader__label {
  color: #bec2ca;
}

.accordion {
  background-color: #ffffff;
  border-color: #f3f3f3;
}
.accordion__toggle {
  color: #8a8a8a;
  background: #ffffff;
}
.accordion__toggle > .figure:before, .accordion__toggle > .figure:after {
  border-color: #8a8a8a;
}
.accordion__action {
  background: #ffffff;
}
.accordion_primary {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#3b71a4), to(#ddf8fe)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #3b71a4, #ddf8fe) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #3b71a4, #ddf8fe) 1 100%;
  -o-border-image: -o-linear-gradient(right, #3b71a4, #ddf8fe) 1 100%;
  border-image: linear-gradient(to right, #3b71a4, #ddf8fe) 1 100%;
}
.accordion_primary-solid {
  border-color: #195ea0;
}
.accordion_success {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#4ec94e), to(#d9f9ca)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #4ec94e, #d9f9ca) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #4ec94e, #d9f9ca) 1 100%;
  -o-border-image: -o-linear-gradient(right, #4ec94e, #d9f9ca) 1 100%;
  border-image: linear-gradient(to right, #4ec94e, #d9f9ca) 1 100%;
}
.accordion_mono {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#8d8d8d), to(#eeeeee)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -o-border-image: -o-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  border-image: linear-gradient(to right, #8d8d8d, #eeeeee) 1 100%;
}
.accordion_alert {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#fb3333), to(#ffe4cc)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #fb3333, #ffe4cc) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #fb3333, #ffe4cc) 1 100%;
  -o-border-image: -o-linear-gradient(right, #fb3333, #ffe4cc) 1 100%;
  border-image: linear-gradient(to right, #fb3333, #ffe4cc) 1 100%;
}

.xp-bar {
  background-color: #f3f3f3;
}

.xp-gained {
  background-image: linear-gradient(to right, #fbd9a4, #F7B44A);
}

.level {
  background-color: #ffffff;
  border: solid 0.2857142857rem #f3f3f3;
}

.user-account-profile-document__list {
  color: #2e3235;
}

.status-box__icon {
  background: #FF728E;
}
.status-box__icon_progress {
  background: #00B740;
}

.user-account-profile-certificate_main {
  border-left-color: #195ea0 !important;
}
.user-account-profile-certificate_is-not-current-education-level {
  background-color: #fce8c8;
}
.user-account-profile-certificate_lock {
  background: #dedee1;
}
.user-account-profile-certificate__frmr-confirmation-status_confirm {
  color: #00B740;
}
.user-account-profile-certificate__my-confirmation {
  color: #2e3235;
}
@media (max-width: 767px) {
  .user-account-profile-certificate__my-confirmation {
    color: #195ea0;
  }
}
.user-account-profile-certificate__my-confirmation .mobile-version {
  color: #195ea0;
}
.user-account-profile-certificate__my-confirmation ::ng-deep .mobile-version {
  color: #195ea0;
}
.user-account-profile-certificate__figure-checkmark {
  color: #00B740;
}
.user-account-profile-certificate__figure-cross:after, .user-account-profile-certificate__figure-cross:before {
  background-color: #195ea0;
}
.user-account-profile-certificate__icon-remove:after, .user-account-profile-certificate__icon-remove:before {
  background-color: #8a8a8a;
}
.user-account-profile-certificate__icon-remove:hover:after, .user-account-profile-certificate__icon-remove:hover:before {
  background-color: #2e3235;
}

::ng-deep .accessible-version .user-account-profile-certificate__icon-remove:focus {
  outline: 0.1rem solid currentColor;
}

.accessible-version .user-account-profile-certificate__icon-remove:focus {
  outline: 0.1rem solid currentColor;
}

@media (max-width: 767px) {
  .user-account-profile-certificate__button:disabled {
    background: none;
  }
}
.mobile-version .user-account-profile-certificate__button:disabled {
  background: none;
}

::ng-deep .mobile-version .user-account-profile-certificate__button:disabled {
  background: none;
}

.profile-titles {
  color: #2e3235;
}
.profile-titles__introduction {
  border-color: #d0d0d0;
}

.toggle-button__slider_primary {
  background-color: #195ea0;
}
.toggle-button__slider_mono {
  background-color: #a1a1a1;
}

.profile-title-item {
  border-color: #d0d0d0;
}

::ng-deep .accessible-version .profile-title-item {
  border-color: inherit;
}

.accessible-version .profile-title-item {
  border-color: inherit;
}

.link {
  color: #195ea0;
}

.submenu__notifications-separator {
  border-bottom: 0.05rem solid #e7e7e7;
}
.submenu-list__link {
  color: #000000;
}
.submenu-list__link_active, .submenu-list__link:hover {
  background: #f2f4f9;
}

@media (max-width: 767px) {
  .submenu-list__link {
    color: #8a8a8a;
  }
  .submenu-list__link_active, .submenu-list__link:hover {
    background: none;
  }
  .submenu_accordion .submenu__toggle:after {
    border-color: #d0d0d0;
  }
}
.mobile-version .submenu-list__link {
  color: #8a8a8a;
}
.mobile-version .submenu-list__link_active, .mobile-version .submenu-list__link:hover {
  background: none;
}
.mobile-version .submenu_accordion .submenu__toggle:after {
  border-color: #d0d0d0;
}

::ng-deep .mobile-version .submenu-list__link {
  color: #8a8a8a;
}
::ng-deep .mobile-version .submenu-list__link_active, ::ng-deep .mobile-version .submenu-list__link:hover {
  background: none;
}
::ng-deep .mobile-version .submenu_accordion .submenu__toggle:after {
  border-color: #d0d0d0;
}

/*@include tablet {
  .submenu {
    &-list {
      &__link {
        &_active, &:hover {
          background: none;
          color: map-get($primary, primary);
        }
      }
    }

    &_accordion {
      .submenu__toggle {
        &:after {
          border-color: map-get($mono, tint20);
        }
      }
    }

  }
}*/
::ng-deep .accessible-version .submenu_accordion .submenu__toggle:after {
  border-color: #000000;
}

.accessible-version .submenu_accordion .submenu__toggle:after {
  border-color: #000000;
}

.edu-element {
  background-color: #ffffff;
}
.edu-element__item {
  border-left-color: #d0d0d0;
}
.edu-element__popup-header-title {
  color: #b8b8b8;
}
.edu-element__popup-header-subtitle {
  color: #2e3235;
}
.edu-element__go-back {
  color: #195ea0;
}

.edu-element-plate-not-mobile {
  background: #ffffff;
}

/*@include tablet {
  .edu-element {
    &__grid-item {
      &_order-2 {
        border-left-color: map-get($mono, tint20);
      }
    }
  }
}*/
@media (max-width: 767px) {
  .edu-element {
    background: none;
  }
  .edu-element__background {
    background-color: #ffffff;
  }
  .edu-element-plate-not-mobile {
    background: none;
  }
  .edu-element-plate-mobile {
    background: #ffffff;
  }
}
.mobile-version .edu-element {
  background: none;
}
.mobile-version .edu-element__background {
  background-color: #ffffff;
}
.mobile-version .edu-element-plate-not-mobile {
  background: none;
}
.mobile-version .edu-element-plate-mobile {
  background: #ffffff;
}

::ng-deep .mobile-version .edu-element {
  background: none;
}
::ng-deep .mobile-version .edu-element__background {
  background-color: #ffffff;
}
::ng-deep .mobile-version .edu-element-plate-not-mobile {
  background: none;
}
::ng-deep .mobile-version .edu-element-plate-mobile {
  background: #ffffff;
}

.datepicker-host.ng-invalid.ng-dirty .text-field {
  background-color: #fff0f3;
}
.datepicker-host.ng-invalid.ng-dirty .text-field::placeholder {
  color: #b8b8b8;
}

.datepicker ngb-datepicker, .datepicker__dropdown-menu {
  background: #ffffff;
}
.datepicker ngb-datepicker .ngb-dp-navigation-chevron, .datepicker__picker .ngb-dp-navigation-chevron {
  border-color: #195ea0;
}
.datepicker ngb-datepicker .ngb-dp-day .btn-light:hover, .datepicker__picker .ngb-dp-day .btn-light:hover {
  background: #467eb3;
  color: #ffffff;
}
.datepicker ngb-datepicker .ngb-dp-day .bg-primary, .datepicker__picker .ngb-dp-day .bg-primary {
  background: #195ea0;
  color: #ffffff;
}
.datepicker ngb-datepicker .ngb-dp-week .ngb-dp-weekday, .datepicker__picker .ngb-dp-week .ngb-dp-weekday {
  color: #bec2ca;
}
.datepicker ngb-datepicker .custom-day.focused, .datepicker__picker .custom-day.focused {
  background-color: #ffffff;
}
.datepicker ngb-datepicker .custom-day.range, .datepicker ngb-datepicker .custom-day:hover, .datepicker__picker .custom-day.range, .datepicker__picker .custom-day:hover {
  background: #195ea0;
  color: #ffffff;
}
.datepicker ngb-datepicker .custom-day.faded, .datepicker__picker .custom-day.faded {
  background: #8caecf;
}
.datepicker ngb-datepicker .ngb-dp-navigation-select .custom-select, .datepicker__picker .ngb-dp-navigation-select .custom-select {
  border: 0.07143rem solid #bec2ca;
}
.datepicker ngb-datepicker .ngb-dp-navigation-select .custom-select:disabled, .datepicker__picker .ngb-dp-navigation-select .custom-select:disabled {
  background: #ffffff;
}
.datepicker__input:disabled {
  background: #f3f3f3;
}
.datepicker__input_invalid:focus, .datepicker__input_invalid {
  background-color: #ffc6d1;
}
.datepicker__input_invalid:focus::placeholder, .datepicker__input_invalid::placeholder {
  color: #b8b8b8;
}

::ng-deep .accessible-version .datepicker__toggle:focus {
  background: #000000;
}
::ng-deep .accessible-version .datepicker__toggle:focus .icon {
  color: #ffffff;
}
::ng-deep .accessible-version .datepicker__reset-button:focus {
  background: #000000;
}
::ng-deep .accessible-version .datepicker__reset-button:focus .figure {
  color: #ffffff;
}
::ng-deep .accessible-version .ngb-dp-day:focus {
  background: #000000;
  color: #ffffff;
  text-decoration: underline;
}
::ng-deep .accessible-version .ngb-dp-navigation-select .custom-select:focus {
  background: #000000;
  color: #ffffff;
}

.accessible-version .datepicker__toggle:focus {
  background: #000000;
}
.accessible-version .datepicker__toggle:focus .icon {
  color: #ffffff;
}
.accessible-version .datepicker__reset-button:focus {
  background: #000000;
}
.accessible-version .datepicker__reset-button:focus .figure {
  color: #ffffff;
}
.accessible-version .ngb-dp-day:focus {
  background: #000000;
  color: #ffffff;
  text-decoration: underline;
}
.accessible-version .ngb-dp-navigation-select .custom-select:focus {
  background: #000000;
  color: #ffffff;
}

.multi-zet__text-multiplier {
  background: #00B740;
  color: #ffffff;
}

@media (max-width: 767px) {
  .multi-zet_theme_search-mobile .multi-zet__text, .multi-zet_theme_search-mobile .multi-zet__label {
    color: inherit;
  }
}
.mobile-version .multi-zet_theme_search-mobile .multi-zet__text, .mobile-version .multi-zet_theme_search-mobile .multi-zet__label {
  color: inherit;
}

::ng-deep .mobile-version .multi-zet_theme_search-mobile .multi-zet__text, ::ng-deep .mobile-version .multi-zet_theme_search-mobile .multi-zet__label {
  color: inherit;
}

.notifications-list__body {
  background-color: #ffffff;
}
.notifications-list__element {
  border-bottom-color: #b8b8b8;
}

.notification-color_important {
  border-left-color: #ff7700;
}
.notification-color_mediumImportance {
  border-left-color: #f9ca80;
}
.notification-color_lowImportance {
  border-left-color: #144b80;
}

.markers-zet__marker {
  background: #00B740;
  color: #ffffff;
}
@media (max-width: 767px) {
  .markers-zet_theme_search-mobile .markers-zet__text, .markers-zet_theme_search-mobile .markers-zet__label {
    color: inherit;
  }
}
.markers-zet_theme_search-mobile .mobile-version .markers-zet__text, .markers-zet_theme_search-mobile .mobile-version .markers-zet__label {
  color: inherit;
}
.markers-zet_theme_search-mobile ::ng-deep .mobile-version .markers-zet__text, .markers-zet_theme_search-mobile ::ng-deep .mobile-version .markers-zet__label {
  color: inherit;
}

.tab-group__tab {
  background: none;
}
.tab-group_theme_search {
  background: #ffffff;
}
.tab-group_theme_search .tab-group__header {
  background: #f9fafd;
}
.tab-group_theme_search .tab-group__tab_active, .tab-group_theme_search .tab-group__tab_active-on-hover {
  background: #ffffff;
  color: #195ea0;
  box-shadow: 0 0 0.5rem #b8b8b8;
}
.tab-group_theme_search .tab-group__tab_active:after, .tab-group_theme_search .tab-group__tab_active-on-hover:after {
  background: #ffffff;
}
.tab-group_theme_search .tab-group__body {
  background: #ffffff;
}
.tab-group_theme_search-tags .tab-group__tab_active {
  color: #195ea0;
  border-bottom-color: #195ea0;
}

@media (max-width: 767px) {
  .tab-group_theme_search .tab-group__header {
    background: #195ea0;
  }
  .tab-group_theme_search .tab-group__tab {
    background: #195ea0;
    color: #ffffff;
  }
  .tab-group_theme_search .tab-group__tab_active {
    background: #ffffff;
    color: #195ea0;
  }
}
.mobile-version .tab-group_theme_search .tab-group__header {
  background: #195ea0;
}
.mobile-version .tab-group_theme_search .tab-group__tab {
  background: #195ea0;
  color: #ffffff;
}
.mobile-version .tab-group_theme_search .tab-group__tab_active {
  background: #ffffff;
  color: #195ea0;
}

::ng-deep .mobile-version .tab-group_theme_search .tab-group__header {
  background: #195ea0;
}
::ng-deep .mobile-version .tab-group_theme_search .tab-group__tab {
  background: #195ea0;
  color: #ffffff;
}
::ng-deep .mobile-version .tab-group_theme_search .tab-group__tab_active {
  background: #ffffff;
  color: #195ea0;
}

::ng-deep .accessible-version .tab-group_theme_search .tab-group__tab {
  background: #ffffff;
  color: #000000;
}
::ng-deep .accessible-version .tab-group_theme_search .tab-group__tab_active {
  color: #ffffff;
  background: #000000;
}

.accessible-version .tab-group_theme_search .tab-group__tab {
  background: #ffffff;
  color: #000000;
}
.accessible-version .tab-group_theme_search .tab-group__tab_active {
  color: #ffffff;
  background: #000000;
}

.card {
  background: #ffffff;
  border-color: #dedee1;
}
.card__break-ratings-table-container .grid__row_active {
  border: solid 0.1rem #bec2ca;
  background-color: #a3bed9;
}

.sidebar-menu__item {
  padding-left: 2rem;
}
.sidebar-menu__item:not(:last-child) {
  border-bottom-color: #e7e7e7;
}
.sidebar-menu__item_add {
  padding-left: 0;
  border-top-color: #e7e7e7;
}
.sidebar-menu .sidebar-menu__link-text_active {
  border-left-color: #195ea0;
}

.outside-cycles__button {
  background: #e8eef5;
}
.outside-cycles__text {
  border-left-color: transparent;
}
.outside-cycles__text_active {
  border-left-color: #195ea0;
  color: #195ea0;
}

.questionary-progress-bar-container {
  background-color: #d9dce4;
}
.questionary-progress-bar-container__finished {
  background-color: #195ea0;
}

.questionary-container {
  background-color: #ffffff;
}
.questionary-container__loader .loader__stop {
  stop-color: #195ea0;
}
.questionary-container__error {
  color: #FF728E;
}
@media (max-width: 767px) {
  .questionary-container {
    background-color: transparent;
  }
}
.questionary-container .mobile-version {
  background-color: transparent;
}
.questionary-container ::ng-deep .mobile-version {
  background-color: transparent;
}

.psy-questionary-container {
  background-color: #ffffff;
}
.psy-questionary-container__loader .loader__stop {
  stop-color: #195ea0;
}
.psy-questionary-container__error {
  color: #FF728E;
}

@media (max-width: 767px) {
  .psy-questionary-container {
    background-color: unset;
  }
}
.mobile-version .psy-questionary-container {
  background-color: unset;
}

::ng-deep .mobile-version .psy-questionary-container {
  background-color: unset;
}

.topics__icon_dark-grey svg path {
  fill: #434343;
}
.topics__icon_success svg path {
  fill: #00B740;
}
.topics__icon_success svg circle {
  stroke: #00B740;
}
.topics__icon_alert svg path {
  fill: #e5667f;
}
.topics__icon_alert svg circle {
  stroke: #e5667f;
}

.edu-trajectory-topic tr:nth-child(odd) {
  background: #eff3f9;
}
.edu-trajectory-topic__item-title {
  color: #b8b8b8;
}

.tag_primary {
  border-color: #195ea0;
  color: #195ea0;
}

.tag_active {
  background: #195ea0;
}

.tag_active {
  color: #ffffff;
}

.grid__header-col_sorted_asc, .grid__header-col_sorted_desc {
  color: #195ea0;
}
.grid__column {
  color: #434343;
  border-color: #e7e7e7;
}
.grid__header-row-container {
  border: solid 0.1rem #bec2ca;
}
.grid__header-row {
  background: #ffffff;
}
.grid__header-row_bordered .grid__header-col {
  border-color: #e7e7e7;
}
.grid__header-row_fixed th {
  background: #ffffff;
}
.grid__header-row_statistics {
  border-color: #727272;
  background-color: #f3f3f3;
}
.grid__row:nth-child(odd) {
  background: #f2f4f7;
}
.grid__row:nth-child(even) {
  background: #ffffff;
}
.grid_flat__row:nth-child(odd), .grid_flat__row:nth-child(even) {
  background: transparent;
}
.grid_theme_search__column {
  color: #5b5b5b;
}
.grid_theme_search .grid__row:hover .grid__column,
.grid_theme_search .grid__row:hover .grid__column-card {
  background: #f9fafd;
  cursor: pointer;
}
.grid_theme_search .grid__row_cannot-be-opened .grid__column,
.grid_theme_search .grid__row_cannot-be-opened .markers-zet__text {
  color: #b8b8b8;
}
@media (max-width: 767px) {
  .grid_theme_search.grid_mode_card .grid__column-card {
    background: #ffffff;
  }
  .grid_theme_search.grid_mode_card .grid__row {
    background: none;
  }
  .grid_theme_search.grid_mode_card .grid__row:hover .grid__column, .grid_theme_search.grid_mode_card .grid__row:hover .grid__column-card {
    background: #ffffff;
  }
}
.grid_theme_search.grid_mode_card .mobile-version .grid__column-card {
  background: #ffffff;
}
.grid_theme_search.grid_mode_card .mobile-version .grid__row {
  background: none;
}
.grid_theme_search.grid_mode_card .mobile-version .grid__row:hover .grid__column, .grid_theme_search.grid_mode_card .mobile-version .grid__row:hover .grid__column-card {
  background: #ffffff;
}
.grid_theme_search.grid_mode_card ::ng-deep .mobile-version .grid__column-card {
  background: #ffffff;
}
.grid_theme_search.grid_mode_card ::ng-deep .mobile-version .grid__row {
  background: none;
}
.grid_theme_search.grid_mode_card ::ng-deep .mobile-version .grid__row:hover .grid__column, .grid_theme_search.grid_mode_card ::ng-deep .mobile-version .grid__row:hover .grid__column-card {
  background: #ffffff;
}
.grid_theme_statistics {
  position: relative;
}
.grid_theme_statistics .grid__column {
  text-align: left;
}
.grid_theme_statistics .grid__column_photo {
  padding: 1rem 0 1rem 0rem;
  width: 3rem;
}
.grid_theme_statistics .grid__column_name {
  padding: 1rem 0 1rem 0rem;
  width: 5rem;
}
.grid_theme_statistics .grid__column_number {
  padding: 1rem 0 1rem 0.5rem;
  width: 2rem;
}
.grid_theme_statistics .grid__column_zet {
  padding: 1rem 0 1rem 0rem;
  width: 3rem;
}
.grid_theme_statistics .user-ratings__user-photo {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  background-position: center;
  background-size: cover;
}

@media (max-width: 767px) {
  .grid__row:nth-child(odd) {
    background: #ffffff;
  }
}
.mobile-version .grid__row:nth-child(odd) {
  background: #ffffff;
}

::ng-deep .mobile-version .grid__row:nth-child(odd) {
  background: #ffffff;
}

/*
@include tablet {
  .grid {

    &__row {
      &:nth-child(odd) {
        background: $white;
      }
    }
  }
}
*/
.search__common-filters {
  background: #ffffff;
}
.search__loader .loader__stop {
  stop-color: #144b80;
}
.search__results {
  background: #ffffff;
}
.search__content {
  background: #f9fafd;
}
.search__remove-tag-selection .figure {
  background: #195ea0;
}
.search__remove-tag-selection .figure::before, .search__remove-tag-selection .figure::after {
  background: #ffffff;
}
.search__tags {
  background: #f9fafd;
}
.search__partition_underline {
  border-bottom-color: #195ea0;
  color: #195ea0;
}
@media (max-width: 767px) {
  .search__results {
    background: #f9fafd;
  }
  .search__content {
    background: #ffffff;
  }
  .search__tags {
    background: #ffffff;
  }
  .search__notification-wrapper {
    background: #f9fafd;
  }
}
.search .mobile-version__results {
  background: #f9fafd;
}
.search .mobile-version__content {
  background: #ffffff;
}
.search .mobile-version__tags {
  background: #ffffff;
}
.search .mobile-version__notification-wrapper {
  background: #f9fafd;
}
.search ::ng-deep .mobile-version__results {
  background: #f9fafd;
}
.search ::ng-deep .mobile-version__content {
  background: #ffffff;
}
.search ::ng-deep .mobile-version__tags {
  background: #ffffff;
}
.search ::ng-deep .mobile-version__notification-wrapper {
  background: #f9fafd;
}

@media (max-width: 767px) {
  .search__results {
    background: transparent;
  }
  .search__content {
    background: #ffffff;
  }
}
.mobile-version .search__results {
  background: transparent;
}
.mobile-version .search__content {
  background: #ffffff;
}

::ng-deep .mobile-version .search__results {
  background: transparent;
}
::ng-deep .mobile-version .search__content {
  background: #ffffff;
}

/*
@include tablet {
  .search {

    &__results {
      background: transparent;
    }

    &__content {
      background: $white;
    }
  }
}
*/
.search-sidebar__toggle-filter-icon {
  color: #0f3860;
}
.search-sidebar__toggle-filter-count {
  color: #ffffff;
  background: #00B740;
}
.search-sidebar__item_border {
  border-top-color: #d0d0d0;
}
.search-sidebar__rating-text {
  color: #434343;
}

.search-item__division {
  color: #a1a1a1;
}
.search-item__organization {
  color: #a1a1a1;
}
.search-item__region {
  color: #195ea0;
}
.search-item__type {
  color: #a1a1a1;
}
.search-item__iom-type {
  color: #a1a1a1;
}
.search-item__iom-kind {
  color: #a1a1a1;
}
.search-item__form {
  color: #a1a1a1;
}
.search-item__zet {
  color: #727272;
}
@media (max-width: 767px) {
  .search-item__name {
    color: #434343;
  }
  .search-item__organization {
    color: #8a8a8a;
  }
  .search-item__speciality {
    color: #727272;
  }
  .search-item__date-range {
    color: #8a8a8a;
  }
  .search-item__date-range-title {
    color: #434343;
  }
}
.search-item .mobile-version__name {
  color: #434343;
}
.search-item .mobile-version__organization {
  color: #8a8a8a;
}
.search-item .mobile-version__speciality {
  color: #727272;
}
.search-item .mobile-version__date-range {
  color: #8a8a8a;
}
.search-item .mobile-version__date-range-title {
  color: #434343;
}
.search-item ::ng-deep .mobile-version__name {
  color: #434343;
}
.search-item ::ng-deep .mobile-version__organization {
  color: #8a8a8a;
}
.search-item ::ng-deep .mobile-version__speciality {
  color: #727272;
}
.search-item ::ng-deep .mobile-version__date-range {
  color: #8a8a8a;
}
.search-item ::ng-deep .mobile-version__date-range-title {
  color: #434343;
}

.search-wizard__header {
  background: #165490;
}
.search-wizard__back {
  color: #ffffff;
}
.search-wizard__menu-button {
  color: #8caecf;
  border-bottom: 0.2142857143rem solid transparent;
}
.search-wizard__menu-button_active {
  border-bottom-color: #ffffff;
}
.search-wizard__menu-button_preset {
  color: #F7B44A;
}
.search-wizard__menu-button_filled {
  color: #ffffff;
}
.search-wizard__icon svg path {
  fill: #165490;
}
.search-wizard__body {
  background: #f9fafd;
}
.search-wizard__title {
  color: #8a8a8a;
}
.search-wizard__subtitle {
  color: #151515;
}

::ng-deep .accessible-version .search-wizard__header {
  background: #ffffff;
}
::ng-deep .accessible-version .search-wizard__back {
  color: #000000;
}
::ng-deep .accessible-version .search-wizard__menu-button {
  color: #000000;
  border: 0.09rem solid #000000;
  border-radius: 0.2rem;
  margin: 0.1rem;
  padding: 0 1rem;
}
::ng-deep .accessible-version .search-wizard__menu-button_active {
  background: #000000;
  color: #ffffff;
}

.accessible-version .search-wizard__header {
  background: #ffffff;
}
.accessible-version .search-wizard__back {
  color: #000000;
}
.accessible-version .search-wizard__menu-button {
  color: #000000;
  border: 0.09rem solid #000000;
  border-radius: 0.2rem;
  margin: 0.1rem;
  padding: 0 1rem;
}
.accessible-version .search-wizard__menu-button_active {
  background: #000000;
  color: #ffffff;
}

.notification-bubble__close-button {
  background: none;
}
.notification-bubble_theme_white {
  color: #195ea0;
  background: #ffffff;
}
.notification-bubble_theme_search {
  color: #F7B44A;
  background: #f9fafd;
}
.notification-bubble_theme_secondary {
  color: #195ea0;
  background: #f9fafd;
}
.notification-bubble__bubble {
  background: inherit;
}
.notification-bubble__bubble_theme_white:after {
  border-bottom-color: #ffffff;
}
.notification-bubble__bubble_theme_search:after {
  border-bottom-color: #f9fafd;
}
.notification-bubble__bubble_theme_secondary:after {
  border-bottom-color: #f9fafd;
}

@media (max-width: 767px) {
  .notification-bubble__close-button {
    background: #f2f4f7;
  }
  .notification-bubble__close-button .figure {
    color: #195ea0;
  }
}
.mobile-version .notification-bubble__close-button {
  background: #f2f4f7;
}
.mobile-version .notification-bubble__close-button .figure {
  color: #195ea0;
}

::ng-deep .mobile-version .notification-bubble__close-button {
  background: #f2f4f7;
}
::ng-deep .mobile-version .notification-bubble__close-button .figure {
  color: #195ea0;
}

.password-group__btn_toggled svg g {
  fill: #ffffff;
  stroke: #195ea0;
}

::ng-deep .accessible-version .password-group__btn:focus {
  background: #000000;
}
::ng-deep .accessible-version .password-group__btn:focus .icon {
  color: #ffffff;
}

.accessible-version .password-group__btn:focus {
  background: #000000;
}
.accessible-version .password-group__btn:focus .icon {
  color: #ffffff;
}

.schedule__card {
  background-color: #ffffff;
}
.schedule__loader .loader__stop {
  stop-color: #144b80;
}
.schedule__error {
  color: #ffffff;
  background-color: #FF728E;
}

@media (max-width: 767px) {
  .schedule__post-title {
    color: #8a8a8a;
  }
}
.mobile-version .schedule__post-title {
  color: #8a8a8a;
}

::ng-deep .mobile-version .schedule__post-title {
  color: #8a8a8a;
}

::ng-deep .accessible-version .schedule__card {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}

.accessible-version .schedule__card {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}

.card-edu-element {
  background-color: #ffffff;
  color: #2e3235;
}
.card-edu-element__recommendation-separator {
  background: linear-gradient(to bottom, #727272 20%, #d0d0d0 80%);
}
.card-edu-element__recommendation-separator_required {
  background: linear-gradient(to bottom, #FF728E 20%, #ffe2e8 80%);
}
.card-edu-element__recommendation-separator_recommended {
  background: linear-gradient(to bottom, #00B740 20%, #ccf1d9 80%);
}
.card-edu-element__recommendation-separator_optional {
  background: linear-gradient(to bottom, #195ea0 20%, #e8eef5 80%);
}
.card-edu-element__background {
  color: #ededed;
}
.card-edu-element__status:before {
  color: #8a8a8a;
}
.card-edu-element__status_primary:before {
  color: #195ea0;
}
.card-edu-element__status_success:before {
  color: #00B740;
}
.card-edu-element__status_accent:before {
  color: #F7B44A;
}
.card-edu-element__status_no-access:before {
  color: #d84826;
}
.card-edu-element__iom-link_disabled {
  color: #d0d0d0;
}

::ng-deep .accessible-version .card-edu-element__recommendation-separator {
  display: none;
}

.accessible-version .card-edu-element__recommendation-separator {
  display: none;
}

.questionary__loader .loader__stop {
  stop-color: #144b80;
}
.questionary__error {
  color: #FF728E;
}

/*@include tablet {
  .questionary {
    background-color: map-get($theme, white);
  }
}*/
.notification-count {
  background-color: #ff7700;
  color: #ffffff;
}

.questionary-result-item_success {
  color: #00B740;
}
.questionary-result-item_alert {
  color: #FF728E;
}
.questionary-result-item_accent {
  color: #F7B44A;
}

.questionary-rewards__finish-link-label {
  color: #195ea0;
}
.questionary-rewards__btn-self-control-icon path, .questionary-rewards__btn-self-control-icon polygon, .questionary-rewards__btn-self-control-icon polyline {
  stroke: #ffffff;
}

@media (max-width: 767px) {
  .questionary-rewards {
    background: #ffffff;
  }
}
.mobile-version .questionary-rewards {
  background: #ffffff;
}

::ng-deep .mobile-version .questionary-rewards {
  background: #ffffff;
}

.registration-wrapper {
  background-color: #f9fafd;
}

.registration__form {
  background-color: #ffffff;
}

/*@include tablet {
   .registration {
     .iam-screen {
       background: $white;
     }
     &__form {
       background: none;
     }
   }
}*/
@media (max-width: 767px) {
  .registration__form {
    background: none;
  }
}
.mobile-version .registration__form {
  background: none;
}

::ng-deep .mobile-version .registration__form {
  background: none;
}

.registration-form-loader .loader__stop {
  stop-color: #195ea0;
}

.dpp-pk-addresses__plate {
  background: #ffffff;
}

.om-addresses {
  background: #ffffff;
}

.dpp-pk-popup__add-new-work-place {
  color: #195ea0;
}
.dpp-pk-popup__invalid-form-error {
  background-color: #BD362F;
}
.dpp-pk-popup__section_separator {
  border-bottom-color: #d9dce4;
}
.dpp-pk-popup__loader-box {
  background: rgba(255, 255, 255, 0.8);
}

@media (max-width: 767px) {
  .dpp-pk-popup__zet-number {
    color: #151515;
  }
  .dpp-pk-popup__zet-unit {
    color: #151515;
  }
  .dpp-pk-popup__header-date-range .text {
    color: #151515;
  }
  .dpp-pk-popup__section {
    border-bottom-color: #d9dce4;
  }
}
.mobile-version .dpp-pk-popup__zet-number {
  color: #151515;
}
.mobile-version .dpp-pk-popup__zet-unit {
  color: #151515;
}
.mobile-version .dpp-pk-popup__header-date-range .text {
  color: #151515;
}
.mobile-version .dpp-pk-popup__section {
  border-bottom-color: #d9dce4;
}

::ng-deep .mobile-version .dpp-pk-popup__zet-number {
  color: #151515;
}
::ng-deep .mobile-version .dpp-pk-popup__zet-unit {
  color: #151515;
}
::ng-deep .mobile-version .dpp-pk-popup__header-date-range .text {
  color: #151515;
}
::ng-deep .mobile-version .dpp-pk-popup__section {
  border-bottom-color: #d9dce4;
}

.edu-element-dpp-pk-page {
  background: #f9fafd;
}

.edu-element-om-page__main-info {
  background: #ffffff;
}

.included-topic__grid tr:nth-child(odd) {
  background: #eff3f9;
}
.included-topic__grid .grid__row:hover {
  overflow: hidden;
  transition: all 0.1s ease-in;
  transform: translateY(-1%);
}
.included-topic__element-section {
  border-top-color: #d0d0d0;
}
.included-topic__new-element-counter {
  background: #00B740;
}
.included-topic__new-element-counter-label {
  color: #ffffff;
}
.included-topic__button-container:hover {
  background-color: #d1deec;
}
.included-topic__multi-zet {
  background-color: #F7B44A;
  color: #ffffff;
}
.included-topic__button {
  color: #195ea0;
}

::ng-deep .accessible-version .included-topic__button:focus {
  background: #000000;
}
::ng-deep .accessible-version .included-topic__button:focus .figure:after, ::ng-deep .accessible-version .included-topic__button:focus .figure:before {
  background: #ffffff;
}

.accessible-version .included-topic__button:focus {
  background: #000000;
}
.accessible-version .included-topic__button:focus .figure:after, .accessible-version .included-topic__button:focus .figure:before {
  background: #ffffff;
}

.edu-element-rating__leave-estimate-text {
  color: #071c30;
}

.adl._expired {
  border-top-color: #000000;
}
.adl_certificate {
  border-top-color: #00B740;
}
.adl_licence {
  border-top-color: #195ea0;
}
.adl__confirmation:before {
  border-left-color: #00B740;
  border-bottom-color: #00B740;
}
.adl__remove-icon:before, .adl__remove-icon:after {
  background: #8a8a8a;
}
.adl__not-confirmed:before, .adl__not-confirmed:after {
  background: #FF728E;
}
.adl__cross:before, .adl__cross:after {
  background: #195ea0;
}
.adl__approved-msg-container {
  color: #195ea0;
}
.adl__approved-msg-container:before {
  border-left-color: #195ea0;
  border-bottom-color: #195ea0;
}
.adl__header-doc-number {
  color: #8a8a8a;
}
.adl__overlay {
  background: rgba(0, 0, 0, 0.1);
}
.adl__svg-icon {
  color: #eeeeee;
}
.adl-info__autocomplete {
  margin: 0.5rem 0 0;
}

@media (max-width: 767px) {
  .adl {
    background: #ffffff;
  }
  .adl__container {
    background-color: transparent;
  }
}
.mobile-version .adl {
  background: #ffffff;
}
.mobile-version .adl__container {
  background-color: transparent;
}

::ng-deep .mobile-version .adl {
  background: #ffffff;
}
::ng-deep .mobile-version .adl__container {
  background-color: transparent;
}

.portfolio__feedback-button {
  color: #a3bed9;
}
.portfolio__feedback-button_has-feedback {
  color: #195ea0;
}
.portfolio__like-icon {
  color: #00B740;
}
.portfolio__grid {
  background: #ffffff;
}

@media (max-width: 767px) {
  .portfolio__grid {
    background: none;
  }
  .portfolio__grid-row {
    background: none;
  }
}
.mobile-version .portfolio__grid {
  background: none;
}
.mobile-version .portfolio__grid-row {
  background: none;
}

::ng-deep .mobile-version .portfolio__grid {
  background: none;
}
::ng-deep .mobile-version .portfolio__grid-row {
  background: none;
}

/*
@include tablet {
  .portfolio {

    &__grid {
      background: none;
    }
  }
}*/
.portfolio-imob {
  background-color: #ffffff;
}
.portfolio-imob__feedback-button {
  color: #a3bed9;
}
.portfolio-imob__feedback-button_has-feedback {
  color: #195ea0;
}
.portfolio-imob__type-icon {
  color: #195ea0;
}
.portfolio-imob__footer-icons-charge {
  color: #5b5b5b;
}

.assessment-card {
  background: #ffffff;
  /*@include tablet {
    &__try-counter-text-tablet {
      color: map-get($mono, tint40);
    }

    &__zet-label {
      color: map-get($mono, primary);
    }

    &__zet-icon {
      color: map-get($mono, primary);
    }
  }*/
}
.assessment-card__zet-label {
  color: #195ea0;
}
.assessment-card__zet-label_disabled {
  color: #727272;
}
.assessment-card__zet-icon {
  color: #195ea0;
}
.assessment-card__zet-icon_disabled {
  color: #727272;
}
.assessment-card__title-icon {
  color: #195ea0;
}
.assessment-card__title-icon_disabled {
  color: #727272;
}
.assessment-card__title-text_disabled {
  color: #727272;
}
.assessment-card__try-counter-icon_mono circle {
  fill: #b8b8b8;
}
.assessment-card__tooltip {
  background-color: #ffffff;
}
.assessment-card__tooltip_top:after {
  border-color: #ffffff transparent transparent transparent;
}
.assessment-card__watch-result {
  background-color: #ffffff;
}
@media (max-width: 767px) {
  .assessment-card__zet-label {
    color: #151515;
  }
  .assessment-card__zet-label_disabled {
    color: #727272;
  }
  .assessment-card__zet-icon {
    color: #151515;
  }
  .assessment-card__zet-icon_disabled {
    color: #727272;
  }
}
.assessment-card .mobile-version__zet-label {
  color: #151515;
}
.assessment-card .mobile-version__zet-label_disabled {
  color: #727272;
}
.assessment-card .mobile-version__zet-icon {
  color: #151515;
}
.assessment-card .mobile-version__zet-icon_disabled {
  color: #727272;
}
.assessment-card ::ng-deep .mobile-version__zet-label {
  color: #151515;
}
.assessment-card ::ng-deep .mobile-version__zet-label_disabled {
  color: #727272;
}
.assessment-card ::ng-deep .mobile-version__zet-icon {
  color: #151515;
}
.assessment-card ::ng-deep .mobile-version__zet-icon_disabled {
  color: #727272;
}

.frmr-certificates-confirmation-loader .loader__stop {
  stop-color: #195ea0;
}
.frmr-certificates-confirmation-loader .loader__text {
  fill: #8a8a8a;
}
.frmr-certificates-confirmation-loader .loader__label {
  color: #8a8a8a;
}

.tac-confirmation {
  background: #ffffff;
}

.edu-element-view-about {
  /*@include tablet {
    &__row-cell {
      &:first-child {
        border-right-color: map-get($mono, tint20);
      }
    }
  }*/
}
.edu-element-view-about__all-specialties {
  color: #195ea0;
}
.edu-element-view-about__line:after {
  background: #d0d0d0;
}

.zet-count__multi-zet {
  background-color: #F7B44A;
}

.profile-settings__successful-sync {
  color: #8a8a8a;
}
.profile-settings__access-control .checkbox__styled:after {
  border-color: #00B740;
}
.profile-settings__section {
  border-bottom: 0.055rem solid #dedee1;
}
.profile-settings__section:last-of-type {
  border-bottom: none;
}
.profile-settings__loader-box {
  background: rgba(255, 255, 255, 0.8);
}

.dpp-pk-my-cycles__grid {
  border-bottom: 0.055rem solid #dedee1;
}
.dpp-pk-my-cycles__grid:last-of-type {
  border-bottom: none;
}
.dpp-pk-my-cycles__status {
  color: #8a8a8a;
}
.dpp-pk-my-cycles__status_primary {
  color: #195ea0;
}
.dpp-pk-my-cycles__status_success {
  color: #00B740;
}
.dpp-pk-my-cycles__status_accent {
  color: #F7B44A;
}
.dpp-pk-my-cycles__status_no-access, .dpp-pk-my-cycles__status_canceled {
  color: #d84826;
}
.dpp-pk-my-cycles__status-badge {
  color: inherit;
}
.dpp-pk-my-cycles__status-text {
  color: #000000;
}

@media (max-width: 767px) {
  .dpp-pk-my-cycles__status-text {
    color: inherit;
  }
}
.mobile-version .dpp-pk-my-cycles__status-text {
  color: inherit;
}

::ng-deep .mobile-version .dpp-pk-my-cycles__status-text {
  color: inherit;
}

.edu-plan__multi-zet-helper {
  color: #ffffff;
  background-color: #00B740;
}
.edu-plan__loader-box {
  background: rgba(255, 255, 255, 0.8);
}

::ng-deep .accessible-version .edu-plan__button-with-icon:focus {
  background: #000000;
}
::ng-deep .accessible-version .edu-plan__button-with-icon:focus .icon {
  color: #ffffff;
}

.accessible-version .edu-plan__button-with-icon:focus {
  background: #000000;
}
.accessible-version .edu-plan__button-with-icon:focus .icon {
  color: #ffffff;
}

.progress-circle {
  background-color: #ffffff;
}
.progress-circle:before {
  background-color: #ffffff;
}
.progress-circle:after {
  background-color: #ffffff;
}
.progress-circle_secondary {
  background-color: #f9fafd;
}
.progress-circle_secondary:before {
  background-color: #f9fafd;
}
.progress-circle_secondary:after {
  background-color: #f9fafd;
}
.progress-circle__point {
  position: absolute;
  width: 0.3571428571rem;
  height: 0.3571428571rem;
  border-radius: 50%;
  top: 0;
  margin-top: -0.1785714286rem;
  margin-left: -0.1785714286rem;
  left: 4.2857142857rem;
}
.progress-circle__point_success {
  background: #00B740;
}
.progress-circle__point_success:after, .progress-circle__point_success:before {
  background: #00B740;
}
.progress-circle__point_alert {
  background: #FF728E;
}
.progress-circle__point_alert:after, .progress-circle__point_alert:before {
  background: #FF728E;
}
.progress-circle__point_accent {
  background: #F7B44A;
}
.progress-circle__point_accent:after, .progress-circle__point_accent:before {
  background: #F7B44A;
}

.progress-circle__0 .progress-circle__point {
  top: 0.0714285714rem;
  left: 4.2857142857rem;
}
.progress-circle__0 .progress-circle__value {
  transform: translate(-50%, -100%);
}

.progress-circle__1 .progress-circle__point {
  top: 0.0797445016rem;
  left: 4.5503314752rem;
}
.progress-circle__1 .progress-circle__value {
  transform: translate(-46.8604740235%, -99.9013364214%);
}

.progress-circle__2 .progress-circle__point {
  top: 0.104659473rem;
  left: 4.8139043414rem;
}
.progress-circle__2 .progress-circle__value {
  transform: translate(-43.7333383218%, -99.6057350657%);
}

.progress-circle__3 .progress-circle__point {
  top: 0.1460751576rem;
  left: 5.0753926829rem;
}
.progress-circle__3 .progress-circle__value {
  transform: translate(-40.6309342707%, -99.1143625364%);
}

.progress-circle__4 .progress-circle__point {
  top: 0.2038281067rem;
  left: 5.3337645245rem;
}
.progress-circle__4 .progress-circle__value {
  transform: translate(-37.5655056418%, -98.4291580564%);
}

.progress-circle__5 .progress-circle__point {
  top: 0.2776903956rem;
  left: 5.5880001906rem;
}
.progress-circle__5 .progress-circle__value {
  transform: translate(-34.5491502813%, -97.5528258148%);
}

.progress-circle__6 .progress-circle__point {
  top: 0.3673705238rem;
  left: 5.8370963292rem;
}
.progress-circle__6 .progress-circle__value {
  transform: translate(-31.5937723658%, -96.4888242944%);
}

.progress-circle__7 .progress-circle__point {
  top: 0.4725145646rem;
  left: 6.0800698716rem;
}
.progress-circle__7 .progress-circle__value {
  transform: translate(-28.7110354217%, -95.2413526233%);
}

.progress-circle__8 .progress-circle__point {
  top: 0.5927075627rem;
  left: 6.3159619123rem;
}
.progress-circle__8 .progress-circle__value {
  transform: translate(-25.9123162949%, -93.8153340022%);
}

.progress-circle__9 .progress-circle__point {
  top: 0.7274751711rem;
  left: 6.5438414931rem;
}
.progress-circle__9 .progress-circle__value {
  transform: translate(-23.2086602511%, -92.2163962751%);
}

.progress-circle__10 .progress-circle__point {
  top: 0.8762855237rem;
  left: 6.7628092775rem;
}
.progress-circle__10 .progress-circle__value {
  transform: translate(-20.6107373854%, -90.4508497187%);
}

.progress-circle__11 .progress-circle__point {
  top: 1.038551334rem;
  left: 6.9720010997rem;
}
.progress-circle__11 .progress-circle__value {
  transform: translate(-18.1288005126%, -88.5256621388%);
}

.progress-circle__12 .progress-circle__point {
  top: 1.213632213rem;
  left: 7.170591375rem;
}
.progress-circle__12 .progress-circle__value {
  transform: translate(-15.7726447036%, -86.4484313711%);
}

.progress-circle__13 .progress-circle__point {
  top: 1.4008371964rem;
  left: 7.3577963584rem;
}
.progress-circle__13 .progress-circle__value {
  transform: translate(-13.5515686289%, -84.2273552964%);
}

.progress-circle__14 .progress-circle__point {
  top: 1.5994274718rem;
  left: 7.5328772374rem;
}
.progress-circle__14 .progress-circle__value {
  transform: translate(-11.4743378612%, -81.8711994874%);
}

.progress-circle__15 .progress-circle__point {
  top: 1.8086192939rem;
  left: 7.6951430477rem;
}
.progress-circle__15 .progress-circle__value {
  transform: translate(-9.5491502813%, -79.3892626146%);
}

.progress-circle__16 .progress-circle__point {
  top: 2.0275870783rem;
  left: 7.8439534003rem;
}
.progress-circle__16 .progress-circle__value {
  transform: translate(-7.7836037249%, -76.791339749%);
}

.progress-circle__17 .progress-circle__point {
  top: 2.2554666591rem;
  left: 7.9787210088rem;
}
.progress-circle__17 .progress-circle__value {
  transform: translate(-6.1846659978%, -74.0876837051%);
}

.progress-circle__18 .progress-circle__point {
  top: 2.4913586998rem;
  left: 8.0989140068rem;
}
.progress-circle__18 .progress-circle__value {
  transform: translate(-4.7586473767%, -71.2889645783%);
}

.progress-circle__19 .progress-circle__point {
  top: 2.7343322423rem;
  left: 8.2040580477rem;
}
.progress-circle__19 .progress-circle__value {
  transform: translate(-3.5111757056%, -68.4062276342%);
}

.progress-circle__20 .progress-circle__point {
  top: 2.9834283808rem;
  left: 8.2937381758rem;
}
.progress-circle__20 .progress-circle__value {
  transform: translate(-2.4471741852%, -65.4508497187%);
}

.progress-circle__21 .progress-circle__point {
  top: 3.2376640469rem;
  left: 8.3676004648rem;
}
.progress-circle__21 .progress-circle__value {
  transform: translate(-1.5708419436%, -62.4344943582%);
}

.progress-circle__22 .progress-circle__point {
  top: 3.4960358885rem;
  left: 8.4253534138rem;
}
.progress-circle__22 .progress-circle__value {
  transform: translate(-0.8856374636%, -59.3690657293%);
}

.progress-circle__23 .progress-circle__point {
  top: 3.75752423rem;
  left: 8.4667690984rem;
}
.progress-circle__23 .progress-circle__value {
  transform: translate(-0.3942649343%, -56.2666616782%);
}

.progress-circle__24 .progress-circle__point {
  top: 4.0210970963rem;
  left: 8.4916840698rem;
}
.progress-circle__24 .progress-circle__value {
  transform: translate(-0.0986635786%, -53.1395259765%);
}

.progress-circle__25 .progress-circle__point {
  top: 4.2857142857rem;
  left: 8.5rem;
}
.progress-circle__25 .progress-circle__value {
  transform: translate(0%, -50%);
}

.progress-circle__26 .progress-circle__point {
  top: 4.5503314752rem;
  left: 8.4916840698rem;
}
.progress-circle__26 .progress-circle__value {
  transform: translate(-0.0986635786%, -46.8604740235%);
}

.progress-circle__27 .progress-circle__point {
  top: 4.8139043414rem;
  left: 8.4667690984rem;
}
.progress-circle__27 .progress-circle__value {
  transform: translate(-0.3942649343%, -43.7333383218%);
}

.progress-circle__28 .progress-circle__point {
  top: 5.0753926829rem;
  left: 8.4253534138rem;
}
.progress-circle__28 .progress-circle__value {
  transform: translate(-0.8856374636%, -40.6309342707%);
}

.progress-circle__29 .progress-circle__point {
  top: 5.3337645245rem;
  left: 8.3676004648rem;
}
.progress-circle__29 .progress-circle__value {
  transform: translate(-1.5708419436%, -37.5655056418%);
}

.progress-circle__30 .progress-circle__point {
  top: 5.5880001906rem;
  left: 8.2937381758rem;
}
.progress-circle__30 .progress-circle__value {
  transform: translate(-2.4471741852%, -34.5491502813%);
}

.progress-circle__31 .progress-circle__point {
  top: 5.8370963292rem;
  left: 8.2040580477rem;
}
.progress-circle__31 .progress-circle__value {
  transform: translate(-3.5111757056%, -31.5937723658%);
}

.progress-circle__32 .progress-circle__point {
  top: 6.0800698716rem;
  left: 8.0989140068rem;
}
.progress-circle__32 .progress-circle__value {
  transform: translate(-4.7586473767%, -28.7110354217%);
}

.progress-circle__33 .progress-circle__point {
  top: 6.3159619123rem;
  left: 7.9787210088rem;
}
.progress-circle__33 .progress-circle__value {
  transform: translate(-6.1846659978%, -25.9123162949%);
}

.progress-circle__34 .progress-circle__point {
  top: 6.5438414931rem;
  left: 7.8439534003rem;
}
.progress-circle__34 .progress-circle__value {
  transform: translate(-7.7836037249%, -23.208660251%);
}

.progress-circle__35 .progress-circle__point {
  top: 6.7628092775rem;
  left: 7.6951430477rem;
}
.progress-circle__35 .progress-circle__value {
  transform: translate(-9.5491502813%, -20.6107373854%);
}

.progress-circle__36 .progress-circle__point {
  top: 6.9720010997rem;
  left: 7.5328772374rem;
}
.progress-circle__36 .progress-circle__value {
  transform: translate(-11.4743378612%, -18.1288005126%);
}

.progress-circle__37 .progress-circle__point {
  top: 7.170591375rem;
  left: 7.3577963584rem;
}
.progress-circle__37 .progress-circle__value {
  transform: translate(-13.5515686289%, -15.7726447036%);
}

.progress-circle__38 .progress-circle__point {
  top: 7.3577963584rem;
  left: 7.170591375rem;
}
.progress-circle__38 .progress-circle__value {
  transform: translate(-15.7726447036%, -13.5515686289%);
}

.progress-circle__39 .progress-circle__point {
  top: 7.5328772374rem;
  left: 6.9720010997rem;
}
.progress-circle__39 .progress-circle__value {
  transform: translate(-18.1288005126%, -11.4743378612%);
}

.progress-circle__40 .progress-circle__point {
  top: 7.6951430477rem;
  left: 6.7628092775rem;
}
.progress-circle__40 .progress-circle__value {
  transform: translate(-20.6107373854%, -9.5491502813%);
}

.progress-circle__41 .progress-circle__point {
  top: 7.8439534003rem;
  left: 6.5438414931rem;
}
.progress-circle__41 .progress-circle__value {
  transform: translate(-23.2086602511%, -7.7836037249%);
}

.progress-circle__42 .progress-circle__point {
  top: 7.9787210088rem;
  left: 6.3159619123rem;
}
.progress-circle__42 .progress-circle__value {
  transform: translate(-25.9123162949%, -6.1846659978%);
}

.progress-circle__43 .progress-circle__point {
  top: 8.0989140068rem;
  left: 6.0800698716rem;
}
.progress-circle__43 .progress-circle__value {
  transform: translate(-28.7110354217%, -4.7586473767%);
}

.progress-circle__44 .progress-circle__point {
  top: 8.2040580477rem;
  left: 5.8370963292rem;
}
.progress-circle__44 .progress-circle__value {
  transform: translate(-31.5937723658%, -3.5111757056%);
}

.progress-circle__45 .progress-circle__point {
  top: 8.2937381758rem;
  left: 5.5880001906rem;
}
.progress-circle__45 .progress-circle__value {
  transform: translate(-34.5491502813%, -2.4471741852%);
}

.progress-circle__46 .progress-circle__point {
  top: 8.3676004648rem;
  left: 5.3337645245rem;
}
.progress-circle__46 .progress-circle__value {
  transform: translate(-37.5655056418%, -1.5708419436%);
}

.progress-circle__47 .progress-circle__point {
  top: 8.4253534138rem;
  left: 5.0753926829rem;
}
.progress-circle__47 .progress-circle__value {
  transform: translate(-40.6309342707%, -0.8856374636%);
}

.progress-circle__48 .progress-circle__point {
  top: 8.4667690984rem;
  left: 4.8139043414rem;
}
.progress-circle__48 .progress-circle__value {
  transform: translate(-43.7333383218%, -0.3942649343%);
}

.progress-circle__49 .progress-circle__point {
  top: 8.4916840698rem;
  left: 4.5503314752rem;
}
.progress-circle__49 .progress-circle__value {
  transform: translate(-46.8604740235%, -0.0986635786%);
}

.progress-circle__50 .progress-circle__point {
  top: 8.5rem;
  left: 4.2857142857rem;
}
.progress-circle__50 .progress-circle__value {
  transform: translate(-50%, 0%);
}

.progress-circle__51 .progress-circle__point {
  top: 8.4916840698rem;
  left: 4.0210970963rem;
}
.progress-circle__51 .progress-circle__value {
  transform: translate(-53.1395259765%, -0.0986635786%);
}

.progress-circle__52 .progress-circle__point {
  top: 8.4667690984rem;
  left: 3.75752423rem;
}
.progress-circle__52 .progress-circle__value {
  transform: translate(-56.2666616782%, -0.3942649343%);
}

.progress-circle__53 .progress-circle__point {
  top: 8.4253534138rem;
  left: 3.4960358885rem;
}
.progress-circle__53 .progress-circle__value {
  transform: translate(-59.3690657293%, -0.8856374636%);
}

.progress-circle__54 .progress-circle__point {
  top: 8.3676004648rem;
  left: 3.2376640469rem;
}
.progress-circle__54 .progress-circle__value {
  transform: translate(-62.4344943582%, -1.5708419436%);
}

.progress-circle__55 .progress-circle__point {
  top: 8.2937381758rem;
  left: 2.9834283808rem;
}
.progress-circle__55 .progress-circle__value {
  transform: translate(-65.4508497188%, -2.4471741852%);
}

.progress-circle__56 .progress-circle__point {
  top: 8.2040580477rem;
  left: 2.7343322423rem;
}
.progress-circle__56 .progress-circle__value {
  transform: translate(-68.4062276342%, -3.5111757056%);
}

.progress-circle__57 .progress-circle__point {
  top: 8.0989140068rem;
  left: 2.4913586998rem;
}
.progress-circle__57 .progress-circle__value {
  transform: translate(-71.2889645783%, -4.7586473767%);
}

.progress-circle__58 .progress-circle__point {
  top: 7.9787210088rem;
  left: 2.2554666591rem;
}
.progress-circle__58 .progress-circle__value {
  transform: translate(-74.0876837051%, -6.1846659978%);
}

.progress-circle__59 .progress-circle__point {
  top: 7.8439534003rem;
  left: 2.0275870783rem;
}
.progress-circle__59 .progress-circle__value {
  transform: translate(-76.791339749%, -7.7836037249%);
}

.progress-circle__60 .progress-circle__point {
  top: 7.6951430477rem;
  left: 1.8086192939rem;
}
.progress-circle__60 .progress-circle__value {
  transform: translate(-79.3892626146%, -9.5491502813%);
}

.progress-circle__61 .progress-circle__point {
  top: 7.5328772374rem;
  left: 1.5994274718rem;
}
.progress-circle__61 .progress-circle__value {
  transform: translate(-81.8711994874%, -11.4743378612%);
}

.progress-circle__62 .progress-circle__point {
  top: 7.3577963584rem;
  left: 1.4008371964rem;
}
.progress-circle__62 .progress-circle__value {
  transform: translate(-84.2273552964%, -13.5515686289%);
}

.progress-circle__63 .progress-circle__point {
  top: 7.170591375rem;
  left: 1.213632213rem;
}
.progress-circle__63 .progress-circle__value {
  transform: translate(-86.4484313711%, -15.7726447036%);
}

.progress-circle__64 .progress-circle__point {
  top: 6.9720010997rem;
  left: 1.038551334rem;
}
.progress-circle__64 .progress-circle__value {
  transform: translate(-88.5256621388%, -18.1288005126%);
}

.progress-circle__65 .progress-circle__point {
  top: 6.7628092775rem;
  left: 0.8762855237rem;
}
.progress-circle__65 .progress-circle__value {
  transform: translate(-90.4508497187%, -20.6107373854%);
}

.progress-circle__66 .progress-circle__point {
  top: 6.5438414931rem;
  left: 0.7274751711rem;
}
.progress-circle__66 .progress-circle__value {
  transform: translate(-92.2163962751%, -23.2086602511%);
}

.progress-circle__67 .progress-circle__point {
  top: 6.3159619123rem;
  left: 0.5927075627rem;
}
.progress-circle__67 .progress-circle__value {
  transform: translate(-93.8153340021%, -25.9123162949%);
}

.progress-circle__68 .progress-circle__point {
  top: 6.0800698716rem;
  left: 0.4725145646rem;
}
.progress-circle__68 .progress-circle__value {
  transform: translate(-95.2413526232%, -28.7110354217%);
}

.progress-circle__69 .progress-circle__point {
  top: 5.8370963292rem;
  left: 0.3673705238rem;
}
.progress-circle__69 .progress-circle__value {
  transform: translate(-96.4888242942%, -31.5937723657%);
}

.progress-circle__70 .progress-circle__point {
  top: 5.5880001906rem;
  left: 0.2776903956rem;
}
.progress-circle__70 .progress-circle__value {
  transform: translate(-97.5528258144%, -34.5491502812%);
}

.progress-circle__71 .progress-circle__point {
  top: 5.3337645245rem;
  left: 0.2038281067rem;
}
.progress-circle__71 .progress-circle__value {
  transform: translate(-98.4291580558%, -37.5655056417%);
}

.progress-circle__72 .progress-circle__point {
  top: 5.0753926829rem;
  left: 0.1460751577rem;
}
.progress-circle__72 .progress-circle__value {
  transform: translate(-99.1143625355%, -40.6309342706%);
}

.progress-circle__73 .progress-circle__point {
  top: 4.8139043415rem;
  left: 0.1046594732rem;
}
.progress-circle__73 .progress-circle__value {
  transform: translate(-99.6057350642%, -43.7333383216%);
}

.progress-circle__74 .progress-circle__point {
  top: 4.5503314752rem;
  left: 0.0797445018rem;
}
.progress-circle__74 .progress-circle__value {
  transform: translate(-99.901336419%, -46.8604740232%);
}

.progress-circle__75 .progress-circle__point {
  top: 4.2857142858rem;
  left: 0.0714285717rem;
}
.progress-circle__75 .progress-circle__value {
  transform: translate(-99.9999999962%, -49.9999999995%);
}

.progress-circle__76 .progress-circle__point {
  top: 4.0210970963rem;
  left: 0.0797445021rem;
}
.progress-circle__76 .progress-circle__value {
  transform: translate(-99.9013364156%, -53.1395259757%);
}

.progress-circle__77 .progress-circle__point {
  top: 3.7575242301rem;
  left: 0.1046594738rem;
}
.progress-circle__77 .progress-circle__value {
  transform: translate(-99.6057350568%, -56.266661677%);
}

.progress-circle__78 .progress-circle__point {
  top: 3.4960358887rem;
  left: 0.1460751588rem;
}
.progress-circle__78 .progress-circle__value {
  transform: translate(-99.1143625228%, -59.3690657273%);
}

.progress-circle__79 .progress-circle__point {
  top: 3.2376640472rem;
  left: 0.2038281084rem;
}
.progress-circle__79 .progress-circle__value {
  transform: translate(-98.4291580359%, -62.4344943552%);
}

.progress-circle__80 .progress-circle__point {
  top: 2.9834283812rem;
  left: 0.2776903982rem;
}
.progress-circle__80 .progress-circle__value {
  transform: translate(-97.5528257841%, -65.4508497141%);
}

.progress-circle__81 .progress-circle__point {
  top: 2.7343322428rem;
  left: 0.3673705276rem;
}
.progress-circle__81 .progress-circle__value {
  transform: translate(-96.4888242488%, -68.4062276273%);
}

.progress-circle__82 .progress-circle__point {
  top: 2.4913587007rem;
  left: 0.4725145703rem;
}
.progress-circle__82 .progress-circle__value {
  transform: translate(-95.2413525561%, -71.2889645678%);
}

.progress-circle__83 .progress-circle__point {
  top: 2.2554666605rem;
  left: 0.592707571rem;
}
.progress-circle__83 .progress-circle__value {
  transform: translate(-93.8153339037%, -74.0876836895%);
}

.progress-circle__84 .progress-circle__point {
  top: 2.0275870803rem;
  left: 0.7274751832rem;
}
.progress-circle__84 .progress-circle__value {
  transform: translate(-92.2163961318%, -76.7913397258%);
}

.progress-circle__85 .progress-circle__point {
  top: 1.8086192968rem;
  left: 0.8762855412rem;
}
.progress-circle__85 .progress-circle__value {
  transform: translate(-90.4508495115%, -79.3892625806%);
}

.progress-circle__86 .progress-circle__point {
  top: 1.599427476rem;
  left: 1.0385513591rem;
}
.progress-circle__86 .progress-circle__value {
  transform: translate(-88.525661841%, -81.8711994377%);
}

.progress-circle__87 .progress-circle__point {
  top: 1.4008372025rem;
  left: 1.2136322489rem;
}
.progress-circle__87 .progress-circle__value {
  transform: translate(-86.4484309455%, -84.2273552242%);
}

.progress-circle__88 .progress-circle__point {
  top: 1.2136322218rem;
  left: 1.4008372474rem;
}
.progress-circle__88 .progress-circle__value {
  transform: translate(-84.2273546919%, -86.4484312668%);
}

.progress-circle__89 .progress-circle__point {
  top: 1.0385513466rem;
  left: 1.5994275438rem;
}
.progress-circle__89 .progress-circle__value {
  transform: translate(-81.8711986333%, -88.5256619891%);
}

.progress-circle__90 .progress-circle__point {
  top: 0.8762855417rem;
  left: 1.8086193951rem;
}
.progress-circle__90 .progress-circle__value {
  transform: translate(-79.3892614144%, -90.4508495051%);
}

.progress-circle__91 .progress-circle__point {
  top: 0.7274751967rem;
  left: 2.0275872197rem;
}
.progress-circle__91 .progress-circle__value {
  transform: translate(-76.7913380711%, -92.2163959719%);
}

.progress-circle__92 .progress-circle__point {
  top: 0.5927075988rem;
  left: 2.2554668558rem;
}
.progress-circle__92 .progress-circle__value {
  transform: translate(-74.0876813715%, -93.815333574%);
}

.progress-circle__93 .progress-circle__point {
  top: 0.4725146153rem;
  left: 2.491358972rem;
}
.progress-circle__93 .progress-circle__value {
  transform: translate(-71.2889613486%, -95.2413520218%);
}

.progress-circle__94 .progress-circle__point {
  top: 0.3673705946rem;
  left: 2.7343326172rem;
}
.progress-circle__94 .progress-circle__value {
  transform: translate(-68.4062231856%, -96.4888234536%);
}

.progress-circle__95 .progress-circle__point {
  top: 0.2776904942rem;
  left: 2.9834288949rem;
}
.progress-circle__95 .progress-circle__value {
  transform: translate(-65.4508436194%, -97.5528246452%);
}

.progress-circle__96 .progress-circle__point {
  top: 0.2038282432rem;
  left: 3.2376647486rem;
}
.progress-circle__96 .progress-circle__value {
  transform: translate(-62.4344860333%, -98.4291564372%);
}

.progress-circle__97 .progress-circle__point {
  top: 0.1460753457rem;
  left: 3.4960368421rem;
}
.progress-circle__97 .progress-circle__value {
  transform: translate(-59.3690544162%, -99.1143603048%);
}

.progress-circle__98 .progress-circle__point {
  top: 0.1046597311rem;
  left: 3.7575255203rem;
}
.progress-circle__98 .progress-circle__value {
  transform: translate(-56.2666463698%, -99.6057320038%);
}

.progress-circle__99 .progress-circle__point {
  top: 0.0797448542rem;
  left: 4.021098835rem;
}
.progress-circle__99 .progress-circle__value {
  transform: translate(-53.1395053474%, -99.9013322384%);
}

.progress-circle__100 .progress-circle__point {
  top: 0.0714290511rem;
  left: 4.2857166194rem;
}
.progress-circle__100 .progress-circle__value {
  transform: translate(-49.9999723128%, -99.9999943094%);
}

.self-assessment-results-container__loader .loader__stop {
  stop-color: #FF728E;
}
.self-assessment-results-container__detailed-result {
  border-right: 0.0714285rem solid #e7e7e7;
}

@media (max-width: 767px) {
  .self-assessment-results-container__modal-window {
    background: #f9fafd;
  }
  .self-assessment-results-container__detailed-result {
    border-bottom: 0.0714285rem solid #e7e7e7;
  }
}
.mobile-version .self-assessment-results-container__modal-window {
  background: #f9fafd;
}
.mobile-version .self-assessment-results-container__detailed-result {
  border-bottom: 0.0714285rem solid #e7e7e7;
}

::ng-deep .mobile-version .self-assessment-results-container__modal-window {
  background: #f9fafd;
}
::ng-deep .mobile-version .self-assessment-results-container__detailed-result {
  border-bottom: 0.0714285rem solid #e7e7e7;
}

.cycle-select__psy_active.button_stroked {
  background: #195ea0;
}

.login__auth-extra-link {
  color: #195ea0;
}
.login__esia-link {
  color: #434343;
  border-color: #195ea0;
}

.tab-group__tab {
  color: #b8b8b8;
}
.tab-group__tab_active {
  color: #195ea0;
  border-bottom-color: #195ea0;
}

.iam-screen_login {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .iam-screen_login {
    background: none;
  }
}
.mobile-version .iam-screen_login {
  background: none;
}

::ng-deep .mobile-version .iam-screen_login {
  background: none;
}

::ng-deep .accessible-version .login__esia-link:focus {
  outline: 0.1rem solid #000000;
}

.accessible-version .login__esia-link:focus {
  outline: 0.1rem solid #000000;
}

.password-recovery__form {
  background-color: #ffffff;
}
.password-recovery_result .iam-screen {
  background-color: #ffffff;
}

.tab-group__tab {
  color: #b8b8b8;
  background: #ffffff;
}
.tab-group__tab_active {
  color: #195ea0;
  border-bottom-color: #195ea0;
}

/*@include tablet {
  .password-recovery {
    .iam-screen {
      background: $white;
    }
    &__form {
      background: none;
    }
  }
}*/
@media (max-width: 767px) {
  .password-recovery__form {
    background: none;
  }
  .password-recovery_result .iam-screen {
    background: none;
  }
}
.mobile-version .password-recovery__form {
  background: none;
}
.mobile-version .password-recovery_result .iam-screen {
  background: none;
}

::ng-deep .mobile-version .password-recovery__form {
  background: none;
}
::ng-deep .mobile-version .password-recovery_result .iam-screen {
  background: none;
}

.esia-error_result .iam-screen {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .esia-error_result .iam-screen {
    background: none;
  }
}
.mobile-version .esia-error_result .iam-screen {
  background: none;
}

::ng-deep .mobile-version .esia-error_result .iam-screen {
  background: none;
}

.registration-result_result .iam-screen {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .registration-result_result .iam-screen {
    background: none;
  }
}
.mobile-version .registration-result_result .iam-screen {
  background: none;
}

::ng-deep .mobile-version .registration-result_result .iam-screen {
  background: none;
}

.password-recovery-result_result .iam-screen {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .password-recovery-result_result .iam-screen {
    background: none;
  }
}
.mobile-version .password-recovery-result_result .iam-screen {
  background: none;
}

::ng-deep .mobile-version .password-recovery-result_result .iam-screen {
  background: none;
}

.recovery_result .iam-screen {
  background-color: #ffffff;
}

@media (max-width: 767px) {
  .recovery_result .iam-screen {
    background: none;
  }
}
.mobile-version .recovery_result .iam-screen {
  background: none;
}

::ng-deep .mobile-version .recovery_result .iam-screen {
  background: none;
}

.iam-screen-wrapper {
  background-color: #f9fafd;
}

@media (max-width: 767px) {
  .iam-screen-wrapper {
    background: #ffffff;
  }
}
.mobile-version .iam-screen-wrapper {
  background: #ffffff;
}

::ng-deep .mobile-version .iam-screen-wrapper {
  background: #ffffff;
}

.activity-selection__form {
  background-color: #ffffff;
}

.psy-test-card {
  background-color: #ffffff;
  border-bottom-color: #d0d0d0;
  border-top-color: #195ea0;
}

.psy-test-results-container__item-container:nth-child(3n+2):before {
  background-color: #d0d0d0;
}
.psy-test-results-container__item-container:nth-child(3n+3):before {
  background-color: #d0d0d0;
}

::ng-deep .accessible-version .psy-test-card {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}

.accessible-version .psy-test-card {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}

.edu-program-info_success {
  border-top-color: #00B740;
}
.edu-program-info_primary {
  border-top-color: #195ea0;
}
.edu-program-info__tooltip {
  background-color: #ffffff;
}

.edu-element-iom-page__main-info {
  background: #ffffff;
}
.edu-element-iom-page .iom-main-info__no-active-link {
  background-color: #d0d0d0;
}
.edu-element-iom-page__dropdown {
  background-color: #ffffff;
}

.iom-main-info__container {
  background: #ffffff;
}

.edu-trajectory-helper__beyond-topics-container {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#8d8d8d), to(#eeeeee)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -o-border-image: -o-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  border-image: linear-gradient(to right, #8d8d8d, #eeeeee) 1 100%;
  background-color: #ffffff;
}

.select-list__item-button {
  background: #ffffff;
}
.select-list__item-button:hover, .select-list__item-button_active {
  background: #195ea0;
  color: #ffffff;
}
.select-list__item-button:hover {
  background: #467eb3;
}
.select__dropdown-menu {
  background: #ffffff;
}

.edu-header__title {
  color: #8a8a8a;
}
@media (max-width: 767px) {
  .edu-section__card_wide {
    background: #ffffff;
  }
}
.mobile-version .edu-section__card_wide {
  background: #ffffff;
}

::ng-deep .mobile-version .edu-section__card_wide {
  background: #ffffff;
}

.rating-container__total {
  color: #a1a1a1;
}
.rating-container__rating-not-pic:hover {
  color: #a1a1a1;
}

.select-list__item_disabled {
  background: #f3f3f3;
  border-color: #f3f3f3;
  color: #a1a1a1;
}
.select-list__item_bordered-bottom:not(:last-of-type) {
  border-bottom: 0.05rem solid #e7e7e7;
}

.multiselect__button__raised {
  background-color: #195ea0 !important;
  color: #ffffff !important;
}

.multiselect__button__stroked {
  color: #195ea0 !important;
  border: solid #195ea0 1px !important;
}

.multiselect__button__stroked__disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  border-color: rgba(0, 0, 0, 0.26) !important;
}

.multiselect__button__fixed {
  background-color: #FF7700 !important;
  color: #ffffff !important;
}

.full-trajectory-helper__beyond-topics-container {
  -webkit-border-image: -webkit-gradient(linear, 100% 0, 0 0, from(#8d8d8d), to(#eeeeee)) 1 100%;
  -webkit-border-image: -webkit-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -moz-border-image: -moz-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  -o-border-image: -o-linear-gradient(right, #8d8d8d, #eeeeee) 1 100%;
  border-image: linear-gradient(to right, #8d8d8d, #eeeeee) 1 100%;
  background-color: #ffffff;
}

.read-more:after {
  background-image: linear-gradient(to right, rgba(249, 250, 253, 0), #f9fafd 70%, #f9fafd);
}

@media (max-width: 767px) {
  .read-more_theme_specifics:after {
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 70%, #ffffff);
  }
}
.mobile-version .read-more_theme_specifics:after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 70%, #ffffff);
}

::ng-deep .mobile-version .read-more_theme_specifics:after {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff 70%, #ffffff);
}

.edu-topic-mobile__separator {
  border-top-color: #e9eef7;
}

.timer {
  background-color: #ffffff;
}
.timer:before {
  background-color: #ffffff;
}
.timer:after {
  background-color: #ffffff;
}
.timer__point {
  background: #195ea0;
}
.timer__point:after, .timer__point:before {
  background: #195ea0;
}

@media (max-width: 767px) {
  .timer {
    background-color: #f9fafd;
  }
  .timer:before {
    background-color: #f9fafd;
  }
  .timer:after {
    background-color: #f9fafd;
  }
}
.mobile-version .timer {
  background-color: #f9fafd;
}
.mobile-version .timer:before {
  background-color: #f9fafd;
}
.mobile-version .timer:after {
  background-color: #f9fafd;
}

::ng-deep .mobile-version .timer {
  background-color: #f9fafd;
}
::ng-deep .mobile-version .timer:before {
  background-color: #f9fafd;
}
::ng-deep .mobile-version .timer:after {
  background-color: #f9fafd;
}

@media (max-width: 767px) {
  .edu-element-dropdown {
    background: #ffffff;
  }
}
.mobile-version .edu-element-dropdown {
  background: #ffffff;
}

::ng-deep .mobile-version .edu-element-dropdown {
  background: #ffffff;
}

.om-main-info__main-info {
  background: #ffffff;
}

@media (max-width: 767px) {
  .prof-questionary-question__body {
    background: #ffffff;
  }
}
.mobile-version .prof-questionary-question__body {
  background: #ffffff;
}

::ng-deep .mobile-version .prof-questionary-question__body {
  background: #ffffff;
}

.psy-questionary-question__figure-cross:before, .psy-questionary-question__figure-cross:after {
  background: #a1a1a1;
}

@media (max-width: 767px) {
  .psy-questionary-question__body {
    background: #ffffff;
  }
}
.mobile-version .psy-questionary-question__body {
  background: #ffffff;
}

::ng-deep .mobile-version .psy-questionary-question__body {
  background: #ffffff;
}

@media (max-width: 767px) {
  .questionary-results__body {
    background: #ffffff;
  }
}
.mobile-version .questionary-results__body {
  background: #ffffff;
}

::ng-deep .mobile-version .questionary-results__body {
  background: #ffffff;
}

.search-select-question__figure-cross:before, .search-select-question__figure-cross:after {
  background: #a1a1a1;
}

@media (max-width: 767px) {
  .instructions {
    background-color: #ffffff;
  }
}
.mobile-version .instructions {
  background-color: #ffffff;
}

::ng-deep .mobile-version .instructions {
  background-color: #ffffff;
}

.see-annotation__link-text {
  color: #195ea0;
}

@media (max-width: 767px) {
  .see-annotation__link-text {
    color: #000000;
  }
}
.mobile-version .see-annotation__link-text {
  color: #000000;
}

::ng-deep .mobile-version .see-annotation__link-text {
  color: #000000;
}

.profile-awards__filled-icon-tooltip {
  background-color: #ffffff;
}
.profile-awards__filled-icon-tooltip:after {
  border-color: #ffffff transparent transparent transparent;
}

.cdk-overlay-primary {
  background: rgba(0, 0, 0, 0.1);
}

.wrap-navigation-item {
  background: #ffffff;
}
.wrap-navigation-item__link {
  color: #000000;
}
.wrap-navigation-item__link-pic {
  border-color: #8a8a8a;
  color: #8a8a8a;
}

::ng-deep .accessible-version .wrap-navigation-item {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}
::ng-deep .accessible-version .wrap-navigation-item__link:focus {
  background: #000000;
  color: #ffffff;
}
::ng-deep .accessible-version .wrap-navigation-item__link:focus .wrap-navigation-item__text {
  color: #ffffff;
}
::ng-deep .accessible-version .wrap-navigation-item__link:focus .wrap-navigation-item__link-pic {
  border-color: #ffffff;
  color: #ffffff;
}

.accessible-version .wrap-navigation-item {
  border: 0.1rem solid;
  border-radius: 0.1481481481rem;
}
.accessible-version .wrap-navigation-item__link:focus {
  background: #000000;
  color: #ffffff;
}
.accessible-version .wrap-navigation-item__link:focus .wrap-navigation-item__text {
  color: #ffffff;
}
.accessible-version .wrap-navigation-item__link:focus .wrap-navigation-item__link-pic {
  border-color: #ffffff;
  color: #ffffff;
}

.dropdown__content {
  background-color: #ffffff;
}

.dropdown-tree__toggle-text {
  color: #0a4d8d;
}
.dropdown-tree__list {
  border-color: #d0d0d0;
  background: #ffffff;
}
.dropdown-tree__list-element {
  color: #0a4d8d;
}
.dropdown-tree__list-element-plus {
  color: #0a4d8d;
}
.dropdown-tree__list-element_active {
  background: #dedee1;
}
.dropdown-tree__list-element_add:not(.dropdown-tree__list-element_active) {
  background: #f7f7f7;
}
.dropdown-tree__list-element_add-icon {
  color: #a3bed9;
}
.dropdown-tree__popup-under_error {
  color: #fb3333;
}

.checkbox-group_border-bottom .checkbox-group__item {
  border-color: #d0d0d0;
}

.toast {
  background-color: #000000;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}

.toast-container .toast {
  color: #ffffff;
}

.toast-message a,
.toast-message label {
  color: #ffffff;
}

.toast-message a:hover {
  color: #d0d0d0;
}

.toast-close-button {
  color: #ffffff;
}
.toast-close-button:hover, .toast-close-button:focus {
  color: #000000;
}

.toast-close-button {
  text-shadow: 0 0.0555rem 0 #ffffff;
}

::ng-deep .accessible-version .toast-close-button {
  line-height: 1em;
  width: 1em;
  outline: none;
}
::ng-deep .accessible-version .toast-close-button:hover, ::ng-deep .accessible-version .toast-close-button:focus {
  background: #ffffff;
  opacity: 1;
}

.accessible-version .toast-close-button {
  line-height: 1em;
  width: 1em;
  outline: none;
}
.accessible-version .toast-close-button:hover, .accessible-version .toast-close-button:focus {
  background: #ffffff;
  opacity: 1;
}

.priority-org__tooltip {
  background: #ffffff;
}
.priority-org__loader .loader__stop {
  stop-color: #195ea0;
}

.page-indices__arrow {
  color: #195ea0;
}
.page-indices__arrow:hover, .page-indices__arrow:active, .page-indices__option:hover, .page-indices__option:active {
  color: #ff7700;
}
.page-indices__option_selected {
  color: #ff7700;
}

.page-size-options__option {
  color: #195ea0;
}
.page-size-options__option:hover, .page-size-options__option:active {
  color: #ff7700;
}
.page-size-options__option_selected {
  color: #ff7700;
}

::ng-deep .accessible-version .page-size-options__option_selected {
  color: #ffffff;
  background: #000000;
}
::ng-deep .accessible-version .page-size-options__option_selected:focus {
  outline: 0.1rem dashed #000000 !important;
}

.accessible-version .page-size-options__option_selected {
  color: #ffffff;
  background: #000000;
}
.accessible-version .page-size-options__option_selected:focus {
  outline: 0.1rem dashed #000000 !important;
}

.status-box__icon {
  background: #FF728E;
}
.status-box__icon_progress {
  background: #00B740;
}

.add-doc__tooltip {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.adl {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.accordion {
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
}

.activity-selection__form {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

@media (max-width: 767px) {
  .activity-selection__form {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .activity-selection__form {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .activity-selection__form {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.assessment-card {
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
}
.assessment-card__tooltip {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}
.assessment-card__tooltip_top:after {
  filter: drop-shadow(0 0.1429em 0.07143em rgba(0, 0, 0, 0.2));
}
.assessment-card__watch-result {
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15);
}

.autocomplete__dropdown-menu {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.button_raised {
  box-shadow: 0 0.7143rem 2.1429rem 0 rgb(164, 195, 255);
}
.button_raised:hover {
  box-shadow: 0 0.7143rem 2.1429rem 0 rgb(164, 195, 255);
}
.button_raised:disabled {
  box-shadow: none;
}

.card-edu-element {
  box-shadow: 0 0.1428571429rem 0.2857142857rem rgba(0, 0, 0, 0.3);
}

.card {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.chart-card {
  box-shadow: 0 0.1428571429rem 0.2857142857rem rgba(0, 0, 0, 0.3);
}
.chart-card_my-plan {
  box-shadow: none;
}

.container_raised {
  box-shadow: 0 0.07143rem 0.2857rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.datepicker ngb-datepicker.dropdown-menu, .datepicker__dropdown-menu {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.user-account-profile-document__list {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.dropdown__content {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.dropdown-tree__list {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.iom {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

.om__view-container {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

.edu-element {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.edu-element-plate-not-mobile {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

@media (max-width: 767px) {
  .edu-element {
    box-shadow: none;
  }
  .edu-element-plate-not-mobile {
    box-shadow: none;
  }
  .edu-element-plate-mobile {
    box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
  }
}
.mobile-version .edu-element {
  box-shadow: none;
}
.mobile-version .edu-element-plate-not-mobile {
  box-shadow: none;
}
.mobile-version .edu-element-plate-mobile {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

::ng-deep .mobile-version .edu-element {
  box-shadow: none;
}
::ng-deep .mobile-version .edu-element-plate-not-mobile {
  box-shadow: none;
}
::ng-deep .mobile-version .edu-element-plate-mobile {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.edu-program-info__tooltip {
  box-shadow: 0 0.1428571429rem 0.2857142857rem 0 rgba(182, 182, 182, 0.51);
}

.edu-section__card_wide {
  box-shadow: 0 2.857rem 4.357rem 0 rgba(223, 226, 233, 0.5);
  /*@include tablet {
    box-shadow: map-get($shadows, edu-section-card-wide-tablet);
  }*/
}

@media (max-width: 767px) {
  .edu-section__card_wide {
    box-shadow: 0 0.1428571429rem 0.2857142857rem 0 #dfe2e9;
  }
}
.mobile-version .edu-section__card_wide {
  box-shadow: 0 0.1428571429rem 0.2857142857rem 0 #dfe2e9;
}

::ng-deep .mobile-version .edu-section__card_wide {
  box-shadow: 0 0.1428571429rem 0.2857142857rem 0 #dfe2e9;
}

.edu-trajectory-helper__beyond-topics-container {
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .edu-element-dropdown {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .edu-element-dropdown {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .edu-element-dropdown {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.form-control-tooltip {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.full-trajectory-helper__beyond-topics-container {
  box-shadow: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.1);
}

.grid__column-card {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.header__nav_top {
  box-shadow: 0 0.1428571429rem 0.2857142857rem 0 rgba(178, 178, 178, 0.2);
}

/*@include tablet {
  .header {
    &__tablet-mobile-mainmenu {
      box-shadow: map-get($shadows, header-shadow);
    }
  }
}*/
::ng-deep .accessible-version .header__nav_top {
  box-shadow: none;
}
::ng-deep .accessible-version .header__menus {
  box-shadow: none;
}

.accessible-version .header__nav_top {
  box-shadow: none;
}
.accessible-version .header__menus {
  box-shadow: none;
}

.included-topic__grid .grid__row:hover {
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
}

.iom-main-info__container {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

.iam-screen_login {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .iam-screen_login {
    box-shadow: none;
  }
}
.mobile-version .iam-screen_login {
  box-shadow: none;
}

::ng-deep .mobile-version .iam-screen_login {
  box-shadow: none;
}

.notifications-list__body {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.om-addresses {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

.om-main-info__main-info {
  box-shadow: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5);
}

.esia-error_result .iam-screen {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .esia-error_result .iam-screen {
    box-shadow: none;
  }
}
.mobile-version .esia-error_result .iam-screen {
  box-shadow: none;
}

::ng-deep .mobile-version .esia-error_result .iam-screen {
  box-shadow: none;
}

.registration-result_result .iam-screen {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .registration-result_result .iam-screen {
    box-shadow: none;
  }
}
.mobile-version .registration-result_result .iam-screen {
  box-shadow: none;
}

::ng-deep .mobile-version .registration-result_result .iam-screen {
  box-shadow: none;
}

.password-recovery-result_result .iam-screen {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .password-recovery-result_result .iam-screen {
    box-shadow: none;
  }
}
.mobile-version .password-recovery-result_result .iam-screen {
  box-shadow: none;
}

::ng-deep .mobile-version .password-recovery-result_result .iam-screen {
  box-shadow: none;
}

.recovery_result .iam-screen {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .recovery_result .iam-screen {
    box-shadow: none;
  }
}
.mobile-version .recovery_result .iam-screen {
  box-shadow: none;
}

::ng-deep .mobile-version .recovery_result .iam-screen {
  box-shadow: none;
}

.password-recovery__form {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}
.password-recovery_result .iam-screen {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

/* @include tablet {
   .password-recovery {

     .iam-screen {
       box-shadow: map-get($shadows, modal-light);
     }

     &__form {
       box-shadow: none;
     }
   }
 }*/
@media (max-width: 767px) {
  .password-recovery__form {
    box-shadow: none;
  }
  .password-recovery_result .iam-screen {
    box-shadow: none;
  }
}
.mobile-version .password-recovery__form {
  box-shadow: none;
}
.mobile-version .password-recovery_result .iam-screen {
  box-shadow: none;
}

::ng-deep .mobile-version .password-recovery__form {
  box-shadow: none;
}
::ng-deep .mobile-version .password-recovery_result .iam-screen {
  box-shadow: none;
}

.popover__content {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}
.popover_theme_dpppk .popover__content, .popover_theme_user-ratings .popover__content {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.tac-confirmation {
  box-shadow: 0 0.07143rem 0.2857rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.portfolio__grid {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.51);
}

@media (max-width: 767px) {
  .portfolio__grid {
    box-shadow: none;
  }
}
.mobile-version .portfolio__grid {
  box-shadow: none;
}

::ng-deep .mobile-version .portfolio__grid {
  box-shadow: none;
}

/*
@include tablet {
  .portfolio {
    &__grid {
      box-shadow: none;
    }
  }
}*/
.questionary-container {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .prof-questionary-question__body {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .prof-questionary-question__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .prof-questionary-question__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.profile-awards__filled-icon-tooltip {
  box-shadow: 0 0.15rem 0.3rem 0.15rem rgba(222, 222, 222, 0.5);
}
.profile-awards__filled-icon-tooltip:after {
  filter: drop-shadow(0 0.1429em 0.07143em rgba(0, 0, 0, 0.2));
}

.psy-questionary-container {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .psy-questionary-question__body {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .psy-questionary-question__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .psy-questionary-question__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.psy-test-card {
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
}

@media (max-width: 767px) {
  .instructions {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .instructions {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .instructions {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .questionary-results__body {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .questionary-results__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .questionary-results__body {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

@media (max-width: 767px) {
  .questionary-rewards {
    box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
  }
}
.mobile-version .questionary-rewards {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

::ng-deep .mobile-version .questionary-rewards {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.registration__form {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

/*@include tablet {
   .registration {
     .iam-screen {
       box-shadow: map-get($shadows, modal-light);
     }
     &__form {
       box-shadow: none;
     }
   }
}*/
@media (max-width: 767px) {
  .registration__form {
    box-shadow: none;
  }
}
.mobile-version .registration__form {
  box-shadow: none;
}

::ng-deep .mobile-version .registration__form {
  box-shadow: none;
}

.search__results {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.51);
}

.search-sidebar {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.51);
}

@media (max-width: 767px) {
  .search__results {
    box-shadow: none;
  }
  .search-sidebar {
    box-shadow: none;
  }
}
.mobile-version .search__results {
  box-shadow: none;
}
.mobile-version .search-sidebar {
  box-shadow: none;
}

::ng-deep .mobile-version .search__results {
  box-shadow: none;
}
::ng-deep .mobile-version .search-sidebar {
  box-shadow: none;
}

/*@include tablet {
  .search {
    &__results {
      box-shadow: none;
    }
  }
}*/
@media (max-width: 767px) {
  .sidebar_popup-mobile .sidebar__content {
    box-shadow: none;
  }
}
.mobile-version .sidebar_popup-mobile .sidebar__content {
  box-shadow: none;
}

::ng-deep .mobile-version .sidebar_popup-mobile .sidebar__content {
  box-shadow: none;
}

.tooltip_white {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}

.toast-container .toast {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}
.toast-container .toast:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
}

.wrap-navigation-item {
  box-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5);
}

.priority-org__tooltip {
  box-shadow: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55);
}

.select__dropdown-menu {
  box-shadow: 0 0.07143rem 0.2143rem 0.07143rem rgba(0, 0, 0, 0.2);
}